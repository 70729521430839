export const ADMIN = {
  getAllRelevantBusinessInfo: 'ADMIN_getAllRelevantBusinessInfo',
};

export const AUTH = {
  createUser: 'AUTH_createUser',
  deleteUser: 'AUTH_deleteUser',
  getUser: 'AUTH_getUser',
};

export const BSDATA = {
  getAllBusinessData: 'BSDATA_getAllBusinessData',
  getBusinessData: 'BSDATA_getBusinessData',
  updateBusinessData: 'BSDATA_updateBusinessData',
  getBusinessDataFromCustomID: 'BSDATA_getBusinessDataFromCustomID',
  getPublicBusinessDataFromCustomID: 'BSDATA_getPublicBusinessDataFromCustomID',
};

export const BUSINESS = {
  createBusiness: 'BUSINESS_createBusiness',
  getBusinessData: 'BUSINESS_getBusinessData',
  getBusinessDataFromCustomID: 'BUSINESS_getBusinessDataFromCustomID',
  validateCustomID: 'BUSINESS_validateCustomID',
  updateBusinessData: 'BUSINESS_updateBusinessData',
  deleteBusiness: 'BUSINESS_deleteBusiness',
};

export const BSACCOUNT = {
  getAllBusinessAccounts: 'BSACCOUNT_getAllBusinessAccounts',
  getBusinessAccount: 'BSACCOUNT_getBusinessAccount',
  updateBusinessAccount: 'BSACCOUNT_updateBusinessAccount',
  createBusinessAccount: 'BSACCOUNT_createBusinessAccount',
  deleteBusinessAccount: 'BSACCOUNT_deleteBusinessAccount',
  getAllRelevantBusinessAccounts: 'BSACCOUNT_getAllRelevantBusinessAccounts',
};

export const BSPROFILE = {
  getAllBusinessProfiles: 'BSPROFILE_getAllBusinessProfiles',
  getBusinessProfile: 'BSPROFILE_getBusinessProfile',
  updateBusinessProfile: 'BSPROFILE_updateBusinessProfile',
  createBusinessProfile: 'BSPROFILE_createBusinessProfile',
  deleteBusinessProfile: 'BSPROFILE_deleteBusinessProfile',
  getBusinessCustomIDs: 'BSPROFILE_getBusinessCustomIDs',
  getBusinessProfileFromCustomID: 'BSPROFILE_getBusinessProfileFromCustomID',
};

export const BSMENU = {
  getAllBusinessMenus: 'BSMENU_getAllBusinessMenus',
  getBusinessMenu: 'BSMENU_getBusinessMenu',
  updateBusinessMenu: 'BSMENU_updateBusinessMenu',
  createBusinessMenu: 'BSMENU_createBusinessMenu',
  deleteBusinessMenu: 'BSMENU_deleteBusinessMenu',
};

export const FACEBOOK = {
  logConversionEvent: 'FACEBOOK_logConversionEvent',
};

export const PROD = {
  getAllProducts: 'PROD_getAllProducts',
  getBusinessProducts: 'PROD_getBusinessProducts',
  getProduct: 'PROD_getProduct',
  updateProduct: 'PROD_updateProduct',
  createProduct: 'PROD_createProduct',
  deleteProduct: 'PROD_deleteProduct',
  getId: 'PROD_getId',
};

export const ORDER = {
  getAllBusinessOrders: 'ORDER_getAllBusinessOrders',
  getOrder: 'ORDER_getOrder',
  updateOrder: 'ORDER_updateOrder',
  createOrder: 'ORDER_createOrder',
  deleteOrder: 'ORDER_deleteOrder',
  deleteBusinessOrders: 'ORDER_deleteBusinessOrders',
  getId: 'ORDER_getId',
  getBusinessOrdersFromInterval: 'ORDER_getBusinessOrdersFromInterval',
  getAllOrdersFromBusiness: 'ORDER_getAllOrdersFromBusiness',
};

export const PLAN = {
  getAllPlans: 'PLAN_getAllPlans',
  getAllBusinessPlans: 'PLAN_getAllBusinessPlans',
  getPlan: 'PLAN_getPlan',
  updatePlan: 'PLAN_updatePlan',
  createPlan: 'PLAN_createPlan',
  deletePlan: 'PLAN_deletePlan',
  deleteAllBusinessPlans: 'PLAN_deleteAllBusinessPlans',
  getId: 'PLAN_getId',
};

export const STATS = {
  getAllStats: 'STATS_getAllStats',
  logBusinessStat: 'STATS_logBusinessStat',
  logJourneyEvent: 'STATS_logJourneyEvent',
  createBusinessStats: 'STATS_createBusinessStats',
  deleteBusinessStats: 'STATS_deleteBusinessStats',
  getBusinessStats: 'STATS_getBusinessStats',
};
