import { Injectable, EventEmitter } from '@angular/core';

import { AnalyticsService, AnalyticEvent } from './analytics.service';

import { BusinessProfile } from '@models/index';

import * as firebase from 'firebase/app';
import 'firebase/functions';

import { COMMONS, FUNCTIONS } from '@utils/index';
import { PROD_MODE } from '@utils/constants';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BusinessProfileService {
  public update = new EventEmitter();
  public softUpdate = new EventEmitter();
  public cacheUpdated = new EventEmitter();
  private allDownloaded = false;
  private businessProfiles: { [id: string]: BusinessProfile } = {};

  // DASHBOARD 2.0
  public dashboardBusinessProfile: BehaviorSubject<BusinessProfile> =
    new BehaviorSubject(new BusinessProfile());

  constructor(private analyticsService: AnalyticsService) {}

  // DASHBOARD 2.0
  public async getDashboardBusinessProfile(businessID: string) {
    let businessProfile = (
      await firebase
        .functions()
        .httpsCallable(FUNCTIONS.BSPROFILE.getBusinessProfile)({
        businessID,
        PROD_MODE,
      })
    ).data as BusinessProfile;

    businessProfile = {
      ...businessProfile,
      description: COMMONS.HTMLToString(businessProfile.description),
      abstract: COMMONS.HTMLToString(businessProfile.abstract),
    };

    COMMONS.customizeColor(businessProfile.color);

    this.dashboardBusinessProfile.next(businessProfile);
  }

  public async updateDashboardBusinessProfile(
    businessProfile: BusinessProfile
  ): Promise<{ response: boolean; data: string }> {
    if (businessProfile.id) {
      businessProfile = COMMONS.deepCopy(businessProfile) as BusinessProfile;

      businessProfile = {
        ...businessProfile,
        description: COMMONS.stringToHTML(businessProfile.description),
        abstract: COMMONS.stringToHTML(businessProfile.abstract),
        files: COMMONS.removeDuplicates(businessProfile.files),
      };

      await firebase
        .functions()
        .httpsCallable(FUNCTIONS.BSPROFILE.updateBusinessProfile)({
        businessProfile,
        PROD_MODE,
      });

      this.getDashboardBusinessProfile(businessProfile.id);

      return { response: true, data: 'Cambios guardados' };
    }
    return {
      response: false,
      data: 'Ocurrió un error al guardar los cambios. Intenta nuevamente.',
    };
  }

  // ...................... 2.0

  public updateData(refresh: boolean = true) {
    this.businessProfiles = {};
    this.allDownloaded = false;
    if (refresh) {
      this.update.next(true);
    } else {
      this.cacheUpdated.next(true);
    }
  }

  private async downloadAllBusinessProfiles() {
    this.businessProfiles = (
      await firebase
        .functions()
        .httpsCallable(FUNCTIONS.BSPROFILE.getAllBusinessProfiles)({
        PROD_MODE,
      })
    ).data;
    this.allDownloaded = true;
  }

  private async downloadBusinessProfile(businessID: string) {
    const businessProfile = (
      await firebase
        .functions()
        .httpsCallable(FUNCTIONS.BSPROFILE.getBusinessProfile)({
        businessID,
        PROD_MODE,
      })
    ).data as BusinessProfile;

    businessProfile.description = COMMONS.HTMLToString(
      businessProfile.description
    );
    businessProfile.abstract = COMMONS.HTMLToString(businessProfile.abstract);
    COMMONS.customizeColor(businessProfile.color);
    this._saveBusinessProfileInCache(businessProfile);
  }

  public async getAllBusinessProfiles(): Promise<BusinessProfile[]> {
    if (!this.allDownloaded) {
      await this.downloadAllBusinessProfiles();
    }
    return COMMONS.arrFromObj(this.businessProfiles) as BusinessProfile[];
  }

  public async getBusinessProfile(
    businessID: string,
    forceDownload = false
  ): Promise<BusinessProfile> {
    if (!this.businessProfiles.hasOwnProperty(businessID) || forceDownload) {
      await this.downloadBusinessProfile(businessID);
    }
    return COMMONS.deepCopy(
      this.businessProfiles[businessID] || {}
    ) as BusinessProfile;
  }

  public async getBusinessProfileFromCustomID(customID: string) {
    const businessProfile = (
      await firebase
        .functions()
        .httpsCallable(FUNCTIONS.BSPROFILE.getBusinessProfileFromCustomID)({
        customID,
        PROD_MODE,
      })
    ).data as BusinessProfile;
    return COMMONS.deepCopy(businessProfile);
  }

  public async getAllBusinessCustomIDs(): Promise<string[]> {
    const businessCustomIDs = (
      await firebase
        .functions()
        .httpsCallable(FUNCTIONS.BSPROFILE.getBusinessCustomIDs)({ PROD_MODE })
    ).data as string[];
    return businessCustomIDs;
  }

  public async updateBusinessProfile(
    businessProfile: BusinessProfile,
    refreshData: boolean = true
  ): Promise<{ response: boolean; data: string }> {
    if (businessProfile.id) {
      businessProfile = COMMONS.deepCopy(businessProfile) as BusinessProfile;
      businessProfile.description = COMMONS.stringToHTML(
        businessProfile.description
      );
      businessProfile.abstract = COMMONS.stringToHTML(businessProfile.abstract);
      businessProfile.files = COMMONS.removeDuplicates(businessProfile.files);
      this.analyticsService.logEvent(AnalyticEvent.UPDATE_PROFILE, {
        locations: businessProfile.locations.length,
        enabledMenu: businessProfile.config.business.menu,
        enabledOrders: businessProfile.config.business.orders,
      });
      await firebase
        .functions()
        .httpsCallable(FUNCTIONS.BSPROFILE.updateBusinessProfile)({
        businessProfile,
        PROD_MODE,
      });
      this.updateData(refreshData);
      return { response: true, data: 'Cambios guardados' };
    }
    return {
      response: false,
      data: 'Ocurrió un error al guardar los cambios. Intenta nuevamente.',
    };
  }

  public async createBusinessProfile(
    businessProfile: BusinessProfile
  ): Promise<{ response: boolean; data: string }> {
    await firebase
      .functions()
      .httpsCallable(FUNCTIONS.BSPROFILE.createBusinessProfile)({
      businessProfile,
      PROD_MODE,
    });
    this.updateData();
    return { response: true, data: 'Perfil creado' };
  }

  public async deleteBusinessProfile(businessID: string): Promise<boolean> {
    await firebase
      .functions()
      .httpsCallable(FUNCTIONS.BSPROFILE.deleteBusinessProfile)({
      businessID,
      PROD_MODE,
    });
    this.updateData();
    return true;
  }

  public async menuIsEnabled(businessID: string): Promise<boolean> {
    if (businessID) {
      const businessProfile = await this.getBusinessProfile(businessID);
      return (
        businessProfile.config.admin.menu &&
        businessProfile.config.business.menu
      );
    }
    return false;
  }

  public async ordersIsEnabled(businessID: string): Promise<boolean> {
    if (businessID) {
      const businessProfile = await this.getBusinessProfile(businessID);
      return (
        businessProfile.config.admin.orders &&
        businessProfile.config.business.orders &&
        this.ordersAreEnabledNow(businessProfile.schedule)
      );
    }
    return false;
  }

  public ordersAreEnabledNow(schedule: any): boolean {
    if (!schedule.active) {
      return true;
    }

    for (const hours of schedule.days[COMMONS.getTodayWeekday()]) {
      const { start, end } = hours;
      const { hour, minutes } = COMMONS.getCurrentHour();
      const startHour = Number(start.slice(0, 2));
      const startMin = Number(start.slice(3, 5));
      const endHour = Number(end.slice(0, 2));
      const endMin = Number(end.slice(3, 5));
      if (
        (hour > startHour || (hour === startHour && minutes >= startMin)) &&
        (hour < endHour || (hour === endHour && minutes < endMin))
      ) {
        return true;
      }
    }
    return false;
  }

  public _saveBusinessProfileInCache(businessProfile: BusinessProfile): void {
    COMMONS.customizeColor(businessProfile.color);
    businessProfile.description = COMMONS.HTMLToString(
      businessProfile.description
    );
    businessProfile.abstract = COMMONS.HTMLToString(businessProfile.abstract);
    this.businessProfiles[businessProfile.id] = businessProfile;
    this.cacheUpdated.next(true);
  }
}
