export class CustomerProfile {
  id: string;
  name: string;
  img: {
    profile: { desktop: string; mobile: string };
  };
  phone: string;
  email: string;
  birthDate: string;

  constructor() {
    this.id = '';
    this.name = '';
    this.img = {
      profile: { desktop: '', mobile: '' },
    };
    this.phone = '';
    this.email = '';
    this.birthDate = '';
  }
}

export class Customer {
  id: string;
  profile: CustomerProfile;
  businessList: any;
  account: {
    uid: string;
    email: string;
    created: boolean;
  };

  constructor() {
    this.id = '';
    this.profile = new CustomerProfile();
    this.businessList = {};
    this.account = {
      uid: '',
      email: '',
      created: false,
    };
  }
}
