import { Injectable, EventEmitter } from '@angular/core';

// SERVICES
import { AnalyticsService, AnalyticEvent } from './analytics.service';

// MODELS
import { User } from '@models/user';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import { FUNCTIONS } from '@utils/index';
import { PROD_MODE } from '@utils/constants';

@Injectable()
export class AuthService {
  private user = new User();
  public updatedUser = new EventEmitter();

  constructor(private analyticsService: AnalyticsService) {}

  public init(): void {
    this.setPersistence();
    this.listenToAuthStateChange();
  }

  private async setPersistence() {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  }

  private async listenToAuthStateChange() {
    const that = this;
    firebase.auth().onAuthStateChanged(async function (user) {
      if (user) {
        await that.setCurrentUser(user.uid);
      }
      that.updatedUser.next(true);
    });
  }

  private async setCurrentUser(uid: string) {
    this.analyticsService.setUserId(uid);
    this.analyticsService.logEvent(AnalyticEvent.LOGIN, {
      uid,
      method: 'email',
      type: this.user.type,
    });
    this.user = (
      await firebase.functions().httpsCallable(FUNCTIONS.AUTH.getUser)({
        uid,
        PROD_MODE,
      })
    ).data;
    await this.handleBusinessControl();
  }

  public async handleBusinessControl() {
    if (this.user && this.user.type === 'admin') {
      const businessID = localStorage.getItem('business');
      if (businessID !== null) {
        localStorage.removeItem('business');
        await this.setCurrentUser(businessID);
        this.updatedUser.next(true);
      }
    }
  }

  // PUBLIC
  public async createUser(email: string, password: string, type: '' | 'admin' | 'business' | 'customer'): Promise<any> {
    return (
      await firebase.functions().httpsCallable(FUNCTIONS.AUTH.createUser)({
        email,
        password,
        type,
        PROD_MODE,
      })
    ).data;
  }

  public async deleteUser(uid: string): Promise<void> {
    await firebase.functions().httpsCallable(FUNCTIONS.AUTH.deleteUser)({
      uid,
      PROD_MODE,
    });
  }

  public async signInWithEmailAndPassword(email: string, password: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          resolve(true);
        })
        .catch((error) => resolve(false));
    });
  }

  public async resetPassword(email: string) {
    return new Promise<boolean>((resolve) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(function () {
          resolve(true);
        })
        .catch(function (error) {
          resolve(false);
        });
    });
  }

  public async logout(): Promise<void> {
    await firebase.auth().signOut();
    this.user = new User();
    this.analyticsService.setUserId(null);
  }

  public getUser = (): User => this.user;

  public getUserUid = (): string => this.user.uid;
}
