<div class="modal-header">
  <h4 class="modal-title">Dirección de entrega</h4>
  <button type="button" class="close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="form-group mb-4">
    <h4 class="form-title">Dirección:</h4>
    <form>
      <input
        id="address"
        name="address"
        ngx-google-places-autocomplete
        ngbAutofocus
        autocomplete="on"
        class="form-control form-control-solid"
        placeholder="Ej. Calle Ayacucho 400"
        [options]="googleAutocompleteOptions"
        [(ngModel)]="address"
        (onAddressChange)="handleAddressChange($event)"
      />
    </form>

    <app-form-validation
      [show]="!validation.address && validation.showErrors"
      [invalidMsg]="'Ingresa la dirección de entrega'"
    >
    </app-form-validation>
  </div>

  <div class="form-group mb-4">
    <h4 class="form-title">
      Indicación extra:
      <span class="text-muted font-weight-400">(Opcional)</span>
    </h4>
    <textarea
      [(ngModel)]="address2"
      class="form-control form-control-solid"
      id="address2"
      name="address2"
      row="4"
      placeholder="Ej. Portón de madera"
    ></textarea>
  </div>

  <div class="form-group mb-0">
    <h4 class="form-title">Seleccionar ubicación:</h4>
    <div class="outer-container">
      <div [style.display]="loaded ? 'none' : 'block'" class="loading-text">
        Cargando mapa...
      </div>
      <div class="map" [style.opacity]="loaded ? '1' : '0'">
        <google-map
          height="100%"
          width="100%"
          [options]="googleMapOptions"
          [center]="center"
          [zoom]="zoom"
          (tilesloaded)="loaded = true"
        ></google-map>
        <i-feather class="pin-icon" name="map-pin"></i-feather>
        <div
          *ngIf="geolocationAllowed"
          class="my-location"
          (click)="selectMyLocation()"
        >
          <img src="assets/img/my-location.png" />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-light" (click)="closeModal()">Cancelar</button>
  <button class="btn btn-primary" (click)="selectLocation()">Continuar</button>
</div>
