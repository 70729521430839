import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-validation',
  templateUrl: './form-validation.component.html',
  styleUrls: ['./form-validation.component.scss'],
})
export class FormValidationComponent {
  @Input() show: boolean;
  @Input() valid: boolean;
  @Input() validMsg = '';
  @Input() invalidMsg = '';
}
