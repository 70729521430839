export class BusinessAccount {
  id: string;
  name: string;
  clientName: string;
  country: string;
  email: string;
  phone: string;
  active: boolean;

  // TO DO: Delete "lastPlan", "steps", "journey" property after PROD Migration to V2
  lastPlan?: string;
  steps?: {
    tutorial: boolean;
    menuShared: boolean;
    suggestionComputer: boolean;
  };
  journey?: {
    OPEN_PROFILE_MODAL?: boolean;
    OPEN_MENU_MODAL?: boolean;
    OPEN_ORDERS_MODAL?: boolean;
    OPEN_PRODUCT_MODAL?: boolean;
    OPEN_SHARE_MENU_MODAL?: boolean;
    CREATE_PRODUCT?: boolean;
    ORDER_DETAILS?: boolean;
    REGISTER_ORDER_MODAL?: boolean;
    CREATE_LOCATION?: boolean;
  };

  createdAt: number;
  updatedAt: number;

  constructor() {
    this.id = '';
    this.name = '';
    this.clientName = '';
    this.country = 'BO';
    this.email = '';
    this.phone = '';
    this.active = true;
    this.createdAt = 0;
    this.updatedAt = 0;
  }
}
