import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as CONSTANTS from './constants';

export function isMobile(): boolean {
  return window.innerWidth <= 767;
}

export function SCREEN_SIZE(): string {
  if (isMobile()) {
    return 'mobile';
  }
  return 'desktop';
}

export function deepCopy(obj: any): any {
  return JSON.parse(JSON.stringify(obj));
}

export function getTimestamp(): number {
  return Date.now();
}

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export function validateEmail(email): boolean {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validateCustomIDFormat(customID: string): boolean {
  const re = /^[a-zA-Z0-9]*$/;
  return re.test(customID) && customID.length >= 3;
}

export function onlyENGChars(text: string): string {
  text = text.replace(/ö/g, 'o');
  text = text.replace(/ç/g, 'c');
  text = text.replace(/ş/g, 's');
  text = text.replace(/ı/g, 'i');
  text = text.replace(/ğ/g, 'g');
  text = text.replace(/ä/g, 'a');
  text = text.replace(/ë/g, 'e');
  text = text.replace(/ï/g, 'i');
  text = text.replace(/ö/g, 'o');
  text = text.replace(/ü/g, 'u');
  text = text.replace(/á/g, 'a');
  text = text.replace(/à/g, 'a');
  text = text.replace(/é/g, 'e');
  text = text.replace(/è/g, 'e');
  text = text.replace(/í/g, 'i');
  text = text.replace(/ì/g, 'i');
  text = text.replace(/ó/g, 'o');
  text = text.replace(/ò/g, 'o');
  text = text.replace(/ú/g, 'u');
  text = text.replace(/ù/g, 'u');
  return text;
}

export function sortArrayByProperty(arr: any[], prop: string, ascending = true) {
  if (arr.length > 0) {
    if (ascending) {
      return arr.sort((a, b) => (a[prop] > b[prop] ? 1 : b[prop] > a[prop] ? -1 : 0));
    } else {
      return arr.sort((a, b) => (a[prop] < b[prop] ? 1 : b[prop] < a[prop] ? -1 : 0));
    }
  }
  return [];
}

export function sortArray(arr: any[], prop: string, ascending: boolean = true) {
  const properties = prop.split('.');
  if (ascending) {
    arr = arr.sort((a, b) => get(b, ...properties) - get(a, ...properties));
  } else {
    arr = arr.sort((a, b) => get(a, ...properties) - get(b, ...properties));
  }
  return arr;

  function get(obj: any, ...props: string[]): any {
    return obj && props.reduce((result, prop) => (result == null ? undefined : result[prop]), obj);
  }
}

export function removeDuplicates(arr: any[]): any[] {
  return Array.from(new Set(arr));
}

export function stringToHTML(text: string): string {
  return text ? text.replace(new RegExp('\n', 'g'), '<br>') : text;
}

export function HTMLToString(text: string): string {
  return text ? text.replace(new RegExp('<br>', 'g'), '\n') : text;
}

// DATE CONVERSIONS
export function stringDateToDate(date: string): Date {
  const year = Number(date.slice(6));
  const month = Number(date.slice(3, 5));
  const day = Number(date.slice(0, 2));
  return new Date(year, month - 1, day);
}

export function dateToStringDate(date: Date): string {
  let day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;
  let month = (date.getMonth() + 1).toString();
  month = month.length > 1 ? month : '0' + month;
  const year = date.getFullYear();
  return day + '-' + month + '-' + year;
}

export function dateToStringDateReverse(date: Date): string {
  let day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;
  let month = (date.getMonth() + 1).toString();
  month = month.length > 1 ? month : '0' + month;
  const year = date.getFullYear();
  return year + '-' + month + '-' + day;
}

export function NgbDateToStringDate(date: NgbDateStruct): string {
  const day = date.day.toString().length > 1 ? date.day : '0' + date.day;
  const month = date.month.toString().length > 1 ? date.month : '0' + date.month;
  const year = date.year;
  return day + '-' + month + '-' + year;
}

export function stringDateToNgbDate(date: string): NgbDateStruct {
  return {
    year: Number(date.slice(6)),
    month: Number(date.slice(3, 5)),
    day: Number(date.slice(0, 2)),
  };
}

export function getTodayNgbDate(): NgbDateStruct {
  const today = new Date();
  return {
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate(),
  };
}

export function getNgbDateToDate(date: NgbDateStruct): Date {
  const newDate = new Date();
  newDate.setFullYear(date.year);
  newDate.setMonth(date.month - 1);
  newDate.setDate(date.day);
  return newDate;
}

export function getNextStringDate(stringDate: string): string {
  const date = stringDateToDate(stringDate);
  const nextDay = new Date(date.setDate(date.getDate() + 1));
  return dateToStringDate(nextDay);
}

export function getThisWeekStart(stringDate: string): string {
  let date = stringDateToDate(stringDate);
  while (date.getDay() !== 1) {
    date = new Date(date.setDate(date.getDate() - 1));
  }
  return dateToStringDate(date);
}

export function getPrevWeekStart(stringDate: string): string {
  let date = stringDateToDate(stringDate);
  date = new Date(date.setDate(date.getDate() - 7));
  while (date.getDay() !== 1) {
    date = new Date(date.setDate(date.getDate() - 1));
  }
  return dateToStringDate(date);
}

export function getEndOfWeek(weekStart: string): string {
  let date = stringDateToDate(weekStart);
  date = new Date(date.setDate(date.getDate() + 6));
  return dateToStringDate(date);
}

export function getWeekDays(date: string) {
  const dates = [];
  for (let i = 0; i < 7; i++) {
    dates.push(date);
    date = getNextStringDate(date);
  }
  return dates;
}

// NEW COMMONS
export function arrFromObj(obj: any) {
  return Object.values(obj);
}

export function getSearchParams(text: string): string[] {
  text = onlyENGChars(text.toLowerCase());
  const searchParams = text.split(' ');
  return searchParams;
}

export function copyToClipboard(text: string) {
  const input = document.createElement('input');
  input.width = 0;
  input.height = 0;
  // input.type = 'hidden';
  input.setAttribute('value', text);
  document.body.appendChild(input);
  input.select();
  document.execCommand('copy');
}

export function contactUs(isUser = false) {
  const text = isUser ? '' : `&text=Quiero más información sobre el servicio`;
  window.open(`https://api.whatsapp.com/send?phone=59179972109${text}`);
}

export function getCityName(cityID: string) {
  return CONSTANTS.BUSINESS_CITIES.find((city) => city.id === cityID).name || '';
}

export function getBusinessType(typeID: string) {
  return CONSTANTS.BUSINESS_TYPES.find((type) => type.id === typeID)?.name || '';
}

export function daysBetweenDates(date1: number, date2: number): number {
  const timeDifference = date2 - date1;
  const daysDifference = timeDifference / (1000 * 3600 * 24);
  return Math.trunc(daysDifference);
}

export function getTimestampsDayLimits(date: Date): {
  start: number;
  end: number;
} {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  const startDate = new Date(date);
  let endDate = new Date(date);
  endDate.setDate(endDate.getDate() + 1);
  endDate = new Date(endDate.getTime() - 1000);
  return { start: startDate.getTime(), end: endDate.getTime() };
}

export function convertDateToLocaleTimeZone(date: Date): Date {
  const timezoneOffset = date.getTimezoneOffset();
  date.setMinutes(date.getMinutes() + timezoneOffset);
  return date;
}

export function getTodayTimestamp(timezoneOffsetInMinutes: number = 0): number {
  return getTodayDate(timezoneOffsetInMinutes).getTime();
}

export function getTomorrowTimestamp(timezoneOffsetInMinutes: number = 0): number {
  const todayDate = getTodayDate(timezoneOffsetInMinutes);
  todayDate.setDate(todayDate.getDate() + 1);
  return todayDate.getTime();
}

export function getTodayDate(timezoneOffsetInMinutes: number = 0): Date {
  const functionsTime = new Date();
  const marketTime = functionsTime.getTime() + timezoneOffsetInMinutes * 60000;
  const todayDate = new Date(marketTime);
  todayDate.setHours(0);
  todayDate.setMinutes(0);
  todayDate.setSeconds(0);
  todayDate.setMilliseconds(0);
  return todayDate;
}

export function getDisplayTextDate(date: Date): string {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export function getDisplayDayMonth(date: Date): string {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  return `${day}/${month}`;
}

export function getTextDate(date: Date): string {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}

export function getDisplayTextHour(date: Date): string {
  const hour = date.getHours().toString();
  const minutes = date.getMinutes().toString();
  return `${hour.length > 1 ? hour : '0' + hour}:${minutes.length > 1 ? minutes : '0' + minutes}`;
}

export function getWeekDayDisplayName(weekDay: string): string {
  if (weekDay === 'mon') return 'Lunes';
  if (weekDay === 'tue') return 'Martes';
  if (weekDay === 'wed') return 'Miércoles';
  if (weekDay === 'thu') return 'Jueves';
  if (weekDay === 'fri') return 'Viernes';
  if (weekDay === 'sat') return 'Sábado';
  if (weekDay === 'sun') return 'Domingo';
  return '';
}

export function getWeekDayDisplayNameFromIndex(index: number): string {
  if (index === 0) return 'Domingo';
  if (index === 1) return 'Lunes';
  if (index === 2) return 'Martes';
  if (index === 3) return 'Miércoles';
  if (index === 4) return 'Jueves';
  if (index === 5) return 'Viernes';
  if (index === 6) return 'Sábado';

  return '';
}

export function getWeekDayIndex(weekDay: string): number {
  if (weekDay === 'mon') return 0;
  if (weekDay === 'tue') return 1;
  if (weekDay === 'wed') return 2;
  if (weekDay === 'thu') return 3;
  if (weekDay === 'fri') return 4;
  if (weekDay === 'sat') return 5;
  if (weekDay === 'sun') return 6;
  return -1;
}

export function getTodayWeekday() {
  const now = new Date();
  const i = now.getDay();
  if (i === 0) return 'sun';
  if (i === 1) return 'mon';
  if (i === 2) return 'tue';
  if (i === 3) return 'wed';
  if (i === 4) return 'thu';
  if (i === 5) return 'fri';
  if (i === 6) return 'sat';
  return '';
}

export function getCurrentHour() {
  const now = new Date();
  const hour = now.getHours();
  const minutes = now.getMinutes();
  return { hour, minutes };
}

export function productAvailableToday(availableDays: any): boolean {
  const today = new Date();
  const n = today.getDay();
  if (n === 0) {
    return availableDays.sun;
  }
  if (n === 1) {
    return availableDays.mon;
  }
  if (n === 2) {
    return availableDays.tue;
  }
  if (n === 3) {
    return availableDays.wed;
  }
  if (n === 4) {
    return availableDays.thu;
  }
  if (n === 5) {
    return availableDays.fri;
  }
  if (n === 6) {
    return availableDays.sat;
  }
  return true;
}

export function getDisplaySchedule(schedule: any) {
  let result = [];
  const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  days.forEach((day) => {
    const currentDay = schedule.days[day];
    let hours = ['-'];
    if (currentDay.length > 0) {
      hours = currentDay.map((hour) => {
        if (hour.start === '00:00' && hour.end === '24:00') {
          return 'Todo el día';
        }
        return `${hour.start}-${hour.end}`;
      });
    }
    result.push({ name: getWeekDayDisplayName(day), hours });
  });
  return result;
}

export function getScheduleHour(hour: string) {
  return hour.substring(0, 2);
}

export function getScheduleMinute(hour: string) {
  return hour.substring(3, 5);
}

export const sleep = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export function customizeColor(color: string) {
  document.documentElement.style.setProperty('--themeColor', color);
  document.documentElement.style.setProperty('--bgThemeColor', `${color}15`);
}

export function convertArrayToObject(array, key = 'id') {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
}

export function sumArrayElements(array) {
  return array.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

export async function downloadImage(imageSrc, fileName = 'image') {
  const image = await fetch(imageSrc);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);

  const link = document.createElement('a');
  link.href = imageURL;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
