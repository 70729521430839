import { SharedModuleLite } from '@shared/shared-lite.module';
import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from '@core/core.module';
import { AppComponent } from './app.component';
import { SortablejsModule } from 'ngx-sortablejs';
import localeSpanish from '@angular/common/locales/es';
import { GtagModule } from 'angular-gtag';

registerLocaleData(localeSpanish);

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    CoreModule,
    HttpClientModule,
    SortablejsModule.forRoot({ animation: 150 }),
    SharedModuleLite,
    GtagModule.forRoot({ trackingId: 'AW-830391498', trackPageviews: true }),
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'es' }],
  bootstrap: [AppComponent],
})
export class AppModule {}
