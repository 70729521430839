import { Injectable } from '@angular/core';
import { BusinessAdminData } from '@models/index';

import * as firebase from 'firebase/app';
import 'firebase/functions';
import { COMMONS, CONSTANTS, FUNCTIONS } from '@utils/index';
import { PROD_MODE } from '@utils/constants';

@Injectable()
export class AdminService {
  public async getAllBusinessInfo(): Promise<{ [key: string]: BusinessAdminData }> {
    return (await firebase.functions().httpsCallable(FUNCTIONS.BSDATA.getAllBusinessData)({ PROD_MODE })).data;
  }

  public async getAllRelevantBusinessInfo(from: Date, to: Date): Promise<{ [key: string]: BusinessAdminData }> {
    return (
      await firebase.functions().httpsCallable(FUNCTIONS.ADMIN.getAllRelevantBusinessInfo)({
        PROD_MODE,
        from: COMMONS.getTimestampsDayLimits(from).start,
        to: COMMONS.getTimestampsDayLimits(to).end,
      })
    ).data;
  }

  public loginAsBusiness(businessID: string) {
    localStorage.setItem('business', businessID);
    window.open(`${CONSTANTS.BASE_DOMAIN}login`);
    // window.open(`http://localhost:4200/login`);
  }
}
