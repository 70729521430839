import { Injectable, EventEmitter } from '@angular/core';

import { ImageService } from './image.service';

import { BusinessMenu, ImageData, Product } from '@models/index';

import * as firebase from 'firebase/app';
import 'firebase/functions';

import { COMMONS, FUNCTIONS } from '@utils/index';
import { PROD_MODE } from '@utils/constants';

@Injectable()
export class ProductService {
  constructor(private imageService: ImageService) {}

  public async getProductID(): Promise<string> {
    return (
      await firebase.functions().httpsCallable(FUNCTIONS.PROD.getId)({
        PROD_MODE,
      })
    ).data;
  }

  public async updateGallery(
    product: Product,
    gallery: ImageData[],
    businessID: string
  ): Promise<Product> {
    const promises = [];

    product.filesToDelete = [];
    for (let i = 0; i < product.img.gallery.length; i++) {
      if (gallery[i].delete) {
        product.filesToDelete = product.filesToDelete.concat(
          product.img.gallery[i].files
        );
      }
    }

    for (let i = product.img.gallery.length; i < gallery.length; i++) {
      product.img.gallery.push({ desktop: '', mobile: '', files: [] });
    }

    for (let i = 0; i < gallery.length; i++) {
      if (gallery[i]) {
        promises.push(this.addGalleryImage(gallery[i], product, businessID, i));
      }
    }

    const updatedGallery = await Promise.all(promises);
    product.img.gallery = updatedGallery.filter((img) => img.desktop);
    return product;
  }

  public async addGalleryImage(
    imageData: ImageData,
    product: Product,
    businessID: string,
    i: number
  ): Promise<{
    desktop: string;
    mobile: string;
    files: string[];
  }> {
    if (!imageData.delete) {
      if (imageData.changed) {
        imageData.id = `${product.id}_${Math.trunc(
          new Date().getTime() / 1000
        )}_${imageData.id}`;
        const basePath = `business/${businessID}/menu/`;
        const files = [
          `${basePath}${imageData.id}`,
          `${basePath}${imageData.id}-mobile`,
        ];
        const { desktop, mobile } = await this.imageService.uploadImage(
          imageData,
          basePath
        );
        return { desktop, mobile, files };
      } else {
        return product.img.gallery[i];
      }
    } else {
      return { desktop: '', mobile: '', files: [] };
    }
  }

  public async duplicateProduct(product: Product): Promise<Product> {
    const copy: Product = COMMONS.deepCopy(product);
    copy.id = await this.getProductID();
    return copy;
  }
}
