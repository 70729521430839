import { Injectable, EventEmitter } from '@angular/core';

import { Plan } from '@models/index';

import * as firebase from 'firebase/app';
import 'firebase/functions';
import { COMMONS, FUNCTIONS } from '@utils/index';
import { PROD_MODE } from '@utils/constants';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PlanService {
  public update = new EventEmitter();
  plans: { [id: string]: Plan } = {};
  allPlansDownloaded = false;
  businessPlans = {};

  // DASHBOARD 2.0
  public dashboardBusinessPlan: BehaviorSubject<Plan> = new BehaviorSubject(new Plan());

  // DASHBOARD 2.0
  public async getDashboardBusinessPlan(planID: string): Promise<void> {
    const plan = (
      await firebase.functions().httpsCallable(FUNCTIONS.PLAN.getPlan)({
        planID,
        PROD_MODE,
      })
    ).data as Plan;

    this.dashboardBusinessPlan.next(plan);
  }

  constructor() {}

  private updateData() {
    this.plans = {};
    this.businessPlans = {};
    this.update.next(true);
  }

  private async downloadPlan(planID: string): Promise<void> {
    const plan = (
      await firebase.functions().httpsCallable(FUNCTIONS.PLAN.getPlan)({
        planID,
        PROD_MODE,
      })
    ).data as Plan;
    this.plans[plan.id] = plan;
  }

  public async downloadAllPlans(): Promise<void> {
    const allPlans = (
      await firebase.functions().httpsCallable(FUNCTIONS.PLAN.getAllPlans)({
        PROD_MODE,
      })
    ).data;
    this.plans = allPlans;
    this.allPlansDownloaded = true;
  }

  public async getAllPlans(): Promise<Plan[]> {
    if (!this.allPlansDownloaded) {
      await this.downloadAllPlans();
    }
    return COMMONS.deepCopy(Object.values(this.plans));
  }

  private async downloadBusinessPlan(businessID: string): Promise<void> {
    const plans = (
      await firebase.functions().httpsCallable(FUNCTIONS.PLAN.getAllBusinessPlans)({
        businessID,
        PROD_MODE,
      })
    ).data as Plan;
    this.businessPlans[businessID] = plans;
  }

  public async getBusinessPlans(businessID: string): Promise<Plan[]> {
    if (!this.businessPlans.hasOwnProperty(businessID)) {
      await this.downloadBusinessPlan(businessID);
    }
    let businessPlans: Plan[] = Object.values(this.businessPlans[businessID]);
    businessPlans = COMMONS.sortArray(businessPlans, 'endDate', false) as Plan[];
    return businessPlans;
  }

  public async getPlan(planID: string): Promise<Plan> {
    if (!this.plans.hasOwnProperty(planID)) {
      await this.downloadPlan(planID);
    }
    return this.plans[planID];
  }

  public async getPlans(businessIDs: string[]): Promise<any> {
    const promises = [];
    businessIDs.forEach((id) => {
      promises.push(this.getPlan(id));
    });
    const plans = await Promise.all(promises);
    const planObj = {};
    plans.forEach((plan: Plan) => {
      planObj[plan.businessID] = plan;
    });
    return planObj;
  }

  public async createPlan(plan: Plan): Promise<{ response: boolean; data: string; planID: string }> {
    const res = await firebase.functions().httpsCallable(FUNCTIONS.PLAN.createPlan)({ plan, PROD_MODE });
    this.updateData();
    return { response: true, data: 'Plan creado', planID: plan.id };
  }

  public async deletePlan(planID: string): Promise<boolean> {
    await firebase.functions().httpsCallable(FUNCTIONS.PLAN.deletePlan)({
      planID,
      PROD_MODE,
    });
    this.updateData();
    return true;
  }

  public async savePlan(plan: Plan): Promise<{ response: boolean; data: string; planID: string }> {
    const res = await firebase.functions().httpsCallable(FUNCTIONS.PLAN.updatePlan)({ plan, PROD_MODE });
    this.updateData();
    return { response: true, data: 'Plan guardado', planID: res.data };
  }

  public async deleteAllBusinessPlans(businessID: string): Promise<any> {
    await firebase.functions().httpsCallable(FUNCTIONS.PLAN.deleteAllBusinessPlans)({
      businessID,
      PROD_MODE,
    });
    return { response: true, data: 'Planes eliminados' };
  }

  public generateNewPlan(businessID: string, startDate: number, type: 'trial' | 'standard', duration: number, price: number): Plan {
    const plan: Plan = new Plan();
    plan.businessID = businessID;
    plan.startDate = startDate;
    const endDate = COMMONS.convertDateToLocaleTimeZone(new Date(startDate));
    plan.endDate = endDate.setDate(endDate.getDate() + (Number(duration) + 1));
    plan.name = type;
    plan.duration = duration;
    plan.price = price;
    return plan;
  }

  public getPlanPrice(type: 'trial' | 'standard', duration: number): number {
    const prices = {
      base: {
        30: 69,
        90: 65 * 3,
        180: 59 * 6,
        360: 55 * 12,
      },
      locations: {
        30: 35,
        90: 33 * 3,
        180: 30 * 6,
        360: 28 * 12,
      },
    };

    if (type === 'trial') {
      return 0;
    } else if (type === 'standard') {
      return prices.base[duration];
    } else {
      return -1;
    }
  }
}
