export class BusinessProfile {
  id: string;
  name: string;
  type: string;
  color: string;
  abstract: string;
  description: string;
  customID: string;
  img: {
    profile: { desktop: string; mobile: string };
  };
  country: string;
  city: string;
  address: string;
  phone: string;
  social: {
    facebook: string;
    instagram: string;
    website: string;
    tiktok: string;
  };
  locations: {
    name: string;
    address: string;
    phone: string;
    available: boolean;
    whatsAppEnabled: boolean;
  }[];
  config: {
    admin: {
      menu: boolean;
      orders: boolean;
      dashboard: boolean;
    };
    business: {
      menu: boolean;
      orders: boolean;
    };
  };
  schedule: {
    active: boolean;
    days: {
      mon: { start: string; end: string }[];
      tue: { start: string; end: string }[];
      wed: { start: string; end: string }[];
      thu: { start: string; end: string }[];
      fri: { start: string; end: string }[];
      sat: { start: string; end: string }[];
      sun: { start: string; end: string }[];
    };
  };
  visible: boolean;
  files: string[];
  createdAt: number;
  updatedAt: number;

  constructor(id: string = '', name: string = '') {
    this.id = id;
    this.name = name;
    this.type = '';
    this.color = '#ffaa00';
    this.abstract = '';
    this.description = '';
    this.customID = '';
    this.img = {
      profile: { desktop: '', mobile: '' },
    };
    this.country = 'BO';
    this.city = '';
    this.address = '';
    this.phone = '';
    this.social = {
      facebook: '',
      instagram: '',
      website: '',
      tiktok: '',
    };
    this.locations = [];
    this.schedule = {
      active: false,
      days: {
        mon: [{ start: '00:00', end: '24:00' }],
        tue: [{ start: '00:00', end: '24:00' }],
        wed: [{ start: '00:00', end: '24:00' }],
        thu: [{ start: '00:00', end: '24:00' }],
        fri: [{ start: '00:00', end: '24:00' }],
        sat: [{ start: '00:00', end: '24:00' }],
        sun: [{ start: '00:00', end: '24:00' }],
      },
    };
    this.visible = false;
    this.files = [];
    this.config = {
      admin: {
        menu: true,
        orders: true,
        dashboard: true,
      },
      business: {
        menu: true,
        orders: false,
      },
    };
    this.createdAt = 0;
    this.updatedAt = 0;
  }
}
