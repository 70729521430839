import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase/app';
import { PROD_MODE } from '@utils/constants';
import { environment } from './../environments/environment';
import { CONSTANTS, COMMONS } from '@utils/index';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  PROD_MODE = PROD_MODE;
  loading = false;

  constructor() {}

  ngOnInit() {
    this.initFirebase();
    COMMONS.customizeColor(CONSTANTS.THEME_COLORS[0]);
  }

  private initFirebase() {
    firebase.initializeApp(environment.firebaseConfig);
  }
}
