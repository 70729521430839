export class ImageData {
  id: string;
  format: 'png' | 'jpeg' | 'jpg';
  display: string;
  new: string;
  delete?: boolean;
  changed: boolean;
  relation: number;
  width: {
    desktop: number;
    mobile: number;
  };
  imageChangedEvent: any;

  constructor(type: string = '', id: string = '') {
    this.id = '';
    this.format = 'jpg';
    this.display = '';
    this.new = '';
    this.delete = false;
    this.changed = false;
    this.relation = 1;
    this.width = {
      desktop: 0,
      mobile: 0,
    };
    this.imageChangedEvent = '';

    if (type === 'BUSINESS_PROFILE') {
      this.id = 'business-profile';
      this.format = 'jpg';
      this.relation = 1;
      this.width = { desktop: 700, mobile: 300 };
    }

    if (type === 'BUSINESS_GALLERY') {
      this.id = `business-gallery-${id}`;
      this.format = 'jpg';
      this.relation = 1;
      this.width = { desktop: 700, mobile: 300 };
    }

    if (type === 'PRODUCT_PROFILE') {
      this.id = 'product-profile';
      this.format = 'jpg';
      this.relation = 1;
      this.width = { desktop: 700, mobile: 300 };
    }

    /*  if (type === 'PROGRAM_REWARD_PROFILE') {
       this.id = 'program-reward' + id;
       this.format = 'png';
       this.relation = 1;
       this.width = { desktop: 500, mobile: 200 };
     } */
  }
}
