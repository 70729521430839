import { NgModule } from '@angular/core';

import { FeatherModule } from 'angular-feather';
import {
  AtSign,
  Activity,
  AlertCircle,
  AlertTriangle,
  ArrowRightCircle,
  Award,
  ArrowRight,
  Briefcase,
  Box,
  Camera,
  Check,
  ArrowLeft,
  BookOpen,
  CheckCircle,
  ChevronDown,
  ChevronUp,
  ChevronRight,
  Clipboard,
  Clock,
  Copy,
  CreditCard,
  Download,
  DollarSign,
  Edit,
  ExternalLink,
  Eye,
  EyeOff,
  Facebook,
  FileText,
  Filter,
  Github,
  Instagram,
  Heart,
  Home,
  Info,
  Link,
  List,
  LogIn,
  LogOut,
  MapPin,
  Menu,
  Minus,
  MinusCircle,
  Monitor,
  MoreHorizontal,
  Move,
  Package,
  PieChart,
  Plus,
  PlusSquare,
  Phone,
  Printer,
  Save,
  Send,
  Search,
  Settings,
  Slash,
  Share2,
  ShoppingBag,
  ShoppingCart,
  Smartphone,
  Smile,
  ThumbsUp,
  ToggleLeft,
  ToggleRight,
  Trash2,
  Truck,
  Upload,
  User,
  Users,
  XCircle,
  X,
} from 'angular-feather/icons';

const icons = {
  AtSign,
  Activity,
  AlertCircle,
  AlertTriangle,
  ArrowLeft,
  ArrowRightCircle,
  Award,
  ArrowRight,
  BookOpen,
  Briefcase,
  Box,
  Camera,
  Check,
  CheckCircle,
  ChevronDown,
  ChevronUp,
  ChevronRight,
  Clipboard,
  Clock,
  Copy,
  CreditCard,
  Download,
  DollarSign,
  Edit,
  ExternalLink,
  Eye,
  EyeOff,
  Facebook,
  FileText,
  Filter,
  Github,
  Instagram,
  Heart,
  Home,
  Info,
  Link,
  List,
  LogIn,
  LogOut,
  MapPin,
  Menu,
  Minus,
  MinusCircle,
  Monitor,
  MoreHorizontal,
  Move,
  Package,
  PieChart,
  Plus,
  PlusSquare,
  Phone,
  Printer,
  Save,
  Send,
  Search,
  Settings,
  Slash,
  Share2,
  ShoppingBag,
  ShoppingCart,
  Smartphone,
  Smile,
  ThumbsUp,
  Trash2,
  Truck,
  ToggleLeft,
  ToggleRight,
  Upload,
  User,
  Users,
  XCircle,
  X,
};

@NgModule({
  imports: [FeatherModule.pick(icons)],
  exports: [FeatherModule],
})
export class IconsModule {}
