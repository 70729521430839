import { AuthService, StatsService } from '@services/index';
import { Component, Input, OnInit, OnChanges, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { COMMONS } from '@utils/index';

@Component({
  selector: 'app-plan-payment-modal',
  templateUrl: './plan-payment-modal.component.html',
  styleUrls: ['./plan-payment-modal.component.scss'],
})
export class PlanPaymentModalComponent implements OnInit {
  @Input() selectedPlan: {
    name: string;
    duration: number;
    price: number;
    monthlyPrice: number;
  } = {
    name: '',
    duration: 0,
    price: 0,
    monthlyPrice: 0,
  };

  public isMobile = COMMONS.isMobile();

  public paymentMethod = 'qr';

  constructor(private activeModal: NgbActiveModal, private authService: AuthService, private statsService: StatsService) {}

  ngOnInit() {
    this.statsService.logJourneyEvent(this.authService.getUserUid(), 'OPEN_PAY_PLAN_MODAL');
  }

  selectPaymentMethod = (method: string) => {
    this.paymentMethod = method;
  };

  contactUs = () => COMMONS.contactUs(true);

  public closeModal() {
    this.activeModal.close();
  }

  public downloadQR(imgSrc) {
    COMMONS.downloadImage(imgSrc, 'qr-menu7');
  }
}
