import { BusinessData } from './business-data.model';
import { Plan } from './plan.model';

export class BusinessAdminData extends BusinessData {
  statEvents: {
    register: any;
    lastDashboardLogin: any;
    lastMenuVisit: any;
    lastInternalOrder: any;
    lastExternalOrder: any;
    journey: any;
  };
  numbers: {
    categoriesLength: number;
    productsLength: number;
    menuVisits: number;
    totalOrders: number;
    internalOrders: number;
    externalOrders: number;
    dashboardLogin: number;
  };
  planExpiration: {
    daysLeft: number;
    expirationMessage: string;
  };
  lastPlan: Plan;
  businessStats: any;
  createdAt: number;

  constructor(id: string = '') {
    super(id);
    this.statEvents = {
      register: null,
      lastDashboardLogin: null,
      lastMenuVisit: null,
      lastInternalOrder: null,
      lastExternalOrder: null,
      journey: null,
    };
    this.numbers = {
      categoriesLength: 0,
      productsLength: 0,
      menuVisits: 0,
      totalOrders: 0,
      internalOrders: 0,
      externalOrders: 0,
      dashboardLogin: 0,
    };
    this.planExpiration = {
      daysLeft: 0,
      expirationMessage: '',
    };
    this.businessStats = {};
    this.lastPlan = null;
    this.createdAt = 0;
  }
}
