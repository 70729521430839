import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/functions';

import { COMMONS, FUNCTIONS } from '@utils/index';
import { PROD_MODE } from '@utils/constants';

export type JourneyEvents =
  | 'OPEN_PROFILE_MODAL'
  | 'OPEN_MENU_MODAL'
  | 'OPEN_PRODUCT_MODAL'
  | 'OPEN_ORDERS_MODAL'
  | 'OPEN_SHARE_MENU_MODAL'
  | 'REGISTER_ORDER_MODAL'
  | 'CREATE_PRODUCT'
  | 'CREATE_LOCATION'
  | 'ORDER_DETAILS'
  | 'OPEN_RENEW_PLAN_MODAL'
  | 'OPEN_PAY_PLAN_MODAL';

@Injectable()
export class StatsService {
  stats = [];
  allDownloaded = false;

  private async downloadAllStats() {
    this.stats = (
      await firebase.functions().httpsCallable(FUNCTIONS.STATS.getAllStats)({
        PROD_MODE,
      })
    ).data;
    this.allDownloaded = true;
  }

  public async getAllStats() {
    if (!this.allDownloaded) {
      await this.downloadAllStats();
    }
    return COMMONS.arrFromObj(this.stats);
  }

  public async logMenuView(businessID: string) {
    this.logStat(businessID, 'views');
  }

  public async logOrder(businessID: string, internalOrder: boolean) {
    this.logStat(businessID, internalOrder ? 'internal-orders' : 'external-orders');
    this.logStat(businessID, 'orders');
  }

  public async logDashboardLogin(businessID: string) {
    this.logStat(businessID, `dashboard-login`);
  }

  private async logStat(businessID: string, event: string) {
    if (businessID) {
      const today = this.getCurrentDate();
      const date = today;
      return await firebase.functions().httpsCallable(FUNCTIONS.STATS.logBusinessStat)({
        businessID,
        event,
        date,
        PROD_MODE,
      });
    }
  }

  public async logJourneyEvent(businessID: string, event: JourneyEvents) {
    if (businessID) {
      return await firebase.functions().httpsCallable(FUNCTIONS.STATS.logJourneyEvent)({
        businessID,
        event,
        PROD_MODE,
      });
    }
  }

  public async createBusinessStats(businessID: string) {
    return await firebase.functions().httpsCallable(FUNCTIONS.STATS.createBusinessStats)({
      businessID,
      PROD_MODE,
    });
  }

  public async deleteBusinessStats(businessID: string) {
    await firebase.functions().httpsCallable(FUNCTIONS.STATS.deleteBusinessStats)({
      businessID,
      PROD_MODE,
    });
  }

  public async getBusinessStats(businessID: string) {
    return await firebase.functions().httpsCallable(FUNCTIONS.STATS.getBusinessStats)({
      businessID,
      PROD_MODE,
    });
  }

  private getCurrentDate() {
    const todayDate = COMMONS.getTodayDate();
    return COMMONS.getTextDate(todayDate);
  }
}
