import * as feather from 'feather-icons';
import * as $ from 'jquery';
import 'bootstrap';
import 'datatables.net';
import 'datatables.net-bs4';

export function initDataTable(
  dataTable: string = 'dataTable',
  trigger: string = 'dataTableRow0'
) {
  const triggerEl = $('#' + trigger);
  if (triggerEl.length) {
    updateDataTable(dataTable);
  } else {
    setTimeout(() => {
      initDataTable(dataTable, trigger);
    }, 100);
  }
}

export function updateDataTable(dataTable: string = 'dataTable') {
  feather.replace();
  $(document).ready(function () {
    const dataTableEl = $('#' + dataTable).DataTable({
      lengthChange: false,
      language: {
        sProcessing: 'Procesando...',
        sLengthMenu: 'Mostrar _MENU_',
        sZeroRecords: 'No se encontraron resultados',
        sEmptyTable: 'Ningún dato disponible en esta tabla',
        sInfo: 'Mostrando del _START_ al _END_ de _TOTAL_',
        sInfoEmpty: 'Mostrando del 0 al 0 de 0',
        sInfoFiltered: '(filtrado de un total de _MAX_ registros)',
        sInfoPostFix: '',
        sSearch: 'Buscar:',
        sUrl: '',
        sInfoThousands: ',',
        sLoadingRecords: 'Cargando...',
        oPaginate: {
          sFirst: 'Primero',
          sLast: 'Último',
          sNext: 'Siguiente',
          sPrevious: 'Anterior',
        },
        oAria: {
          sSortAscending:
            ': Activar para ordenar la columna de manera ascendente',
          sSortDescending:
            ': Activar para ordenar la columna de manera descendente',
        },
      },
      pageLength: 300,
      sDom: 'lrtip',
      scrollX: true,
      ordering: true,
    });

    $('#dataTableSearch').keyup(function () {
      dataTableEl.search($(this).val()).draw();
    });
  });
}
