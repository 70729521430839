import { Injectable, EventEmitter } from '@angular/core';

import { BusinessAccount } from '@models/index';

import * as firebase from 'firebase/app';
import 'firebase/functions';
import { COMMONS, FUNCTIONS } from '@utils/index';
import { PROD_MODE } from '@utils/constants';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BusinessAccountService {
  public update = new EventEmitter();
  public cacheUpdated = new EventEmitter();
  private businessAccounts = {};
  private allDownloaded = false;

  // DASHBOARD 2.0
  public dashboardBusinessAccount: BehaviorSubject<BusinessAccount> = new BehaviorSubject(new BusinessAccount());

  // DASHBOARD 2.0
  public async getDashboardBusinessAccount(businessID: string) {
    const businessMenu = (
      await firebase.functions().httpsCallable(FUNCTIONS.BSACCOUNT.getBusinessAccount)({
        businessID,
        PROD_MODE,
      })
    ).data as BusinessAccount;

    this.dashboardBusinessAccount.next(businessMenu);
  }

  // public async addJourneyEvent(
  //   businessID: string,
  //   journeyEvent: JourneyEvents
  // ) {
  //   const businessAccount: BusinessAccount = await this.getBusinessAccount(
  //     businessID
  //   );

  //   if (!businessAccount.hasOwnProperty('journey')) {
  //     businessAccount.journey = {};
  //   }

  //   if (!businessAccount.journey[journeyEvent]) {
  //     businessAccount.journey[journeyEvent] = true;
  //     this.updateBusinessAccount(businessAccount);
  //   }
  // }

  // PRIVATE
  public updateData() {
    this.businessAccounts = {};
    this.allDownloaded = false;
    this.update.next(true);
  }

  private async downloadAllBusinessAccounts() {
    this.businessAccounts = (
      await firebase.functions().httpsCallable(FUNCTIONS.BSACCOUNT.getAllBusinessAccounts)({
        PROD_MODE,
      })
    ).data;
    this.allDownloaded = true;
  }

  private async downloadBusinessAccount(businessID: string) {
    const businessAccount = (
      await firebase.functions().httpsCallable(FUNCTIONS.BSACCOUNT.getBusinessAccount)({
        businessID,
        PROD_MODE,
      })
    ).data;
    this._saveBusinessAccountInCache(businessAccount);
  }

  // PUBLIC
  public async getAllBusinessAccounts(): Promise<BusinessAccount[]> {
    if (!this.allDownloaded) {
      await this.downloadAllBusinessAccounts();
    }
    return COMMONS.arrFromObj(this.businessAccounts) as BusinessAccount[];
  }

  public async getBusinessAccount(businessID: string, forceDownload = false): Promise<BusinessAccount> {
    if (!this.businessAccounts.hasOwnProperty(businessID) || forceDownload) {
      await this.downloadBusinessAccount(businessID);
    }
    return COMMONS.deepCopy(this.businessAccounts[businessID]) as BusinessAccount;
  }

  public async updateBusinessAccount(businessAccount: BusinessAccount): Promise<{ response: boolean; msg: string }> {
    if (businessAccount.id) {
      await firebase.functions().httpsCallable(FUNCTIONS.BSACCOUNT.updateBusinessAccount)({
        businessAccount,
        PROD_MODE,
      });
      this.updateData();
      return { response: true, msg: 'Cuenta actualizada' };
    }
    return {
      response: false,
      msg: 'Error al actualizar la cuenta. Intenta nuevamente.',
    };
  }

  public async createBusinessAccount(businessAccount: BusinessAccount): Promise<{ response: boolean; msg: string }> {
    await firebase.functions().httpsCallable(FUNCTIONS.BSACCOUNT.createBusinessAccount)({
      businessAccount,
      PROD_MODE,
    });
    this.updateData();
    return { response: true, msg: 'Cuenta creada' };
  }

  public async deleteBusinessAccount(businessID: string): Promise<boolean> {
    await firebase.functions().httpsCallable(FUNCTIONS.BSACCOUNT.deleteBusinessAccount)({
      businessID,
      PROD_MODE,
    });
    this.updateData();
    return true;
  }

  public _saveBusinessAccountInCache(businessAccount: BusinessAccount): void {
    this.businessAccounts[businessAccount.id] = businessAccount;
    this.cacheUpdated.next(true);
  }

  // ADMIN

  public async getAllRelevantBusinessAccounts() {
    const todayStart = COMMONS.getTimestampsDayLimits(new Date()).start;

    return (
      await firebase.functions().httpsCallable(FUNCTIONS.BSACCOUNT.getAllRelevantBusinessAccounts)({
        PROD_MODE,
        todayStart,
      })
    ).data as BusinessAccount;
  }
}
