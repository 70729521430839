<div class="container">
  <div class="text-center mb-4">
    <div *ngIf="!isBusiness">
      <h1 class="big-title">Precios accesibles</h1>
      <p class="lead big-paragraph">Elige el mejor plan para tu negocio</p>
    </div>
    <div *ngIf="isMobile" class="d-flex justify-content-center pb-3 px-2">
      <div class="btn-group" role="group" aria-label="Basic example">
        <button
          type="button"
          (click)="changePricing(12)"
          class="btn pricing-time btn-light"
          [class.btn-sm]="isMobile"
          [class.active]="prices.sel === 12"
        >
          Anual
        </button>
        <button
          type="button"
          (click)="changePricing(6)"
          class="btn pricing-time btn-light"
          [class.btn-sm]="isMobile"
          [class.active]="prices.sel === 6"
        >
          Semestral
        </button>
        <button
          type="button"
          (click)="changePricing(3)"
          class="btn pricing-time btn-light"
          [class.btn-sm]="isMobile"
          [class.active]="prices.sel === 3"
        >
          Trimestral
        </button>
        <button
          type="button"
          (click)="changePricing(1)"
          class="btn pricing-time btn-light"
          [class.btn-sm]="isMobile"
          [class.active]="prices.sel === 1"
        >
          Mensual
        </button>
      </div>
    </div>
  </div>
  <div class="row pt-4">
    <div class="col-12 mb-4 px-0" data-aos="fade-up" data-aos-delay="100">
      <div
        class="card pricing border-lg h-100 card-pricing-width"
        [class.border-light]="prices.sel == 1"
        [class.border-yellow]="prices.sel == 3"
        [class.border-blue]="prices.sel == 6"
        [class.border-success]="prices.sel == 12"
      >
        <div
          class="discount-bubble"
          [class.hidden]="prices.sel == 1"
          [class.bg-yellow]="prices.sel == 3"
          [class.bg-blue]="prices.sel == 6"
          [class.bg-success]="prices.sel == 12"
        >
          {{ prices[prices.sel][1] }}
        </div>
        <div class="card-body p-5">
          <div *ngIf="!isMobile" class="d-flex justify-content-center pb-3 px-2">
            <div class="btn-group" role="group" aria-label="Basic example">
              <button
                type="button"
                (click)="changePricing(12)"
                class="btn pricing-time btn-light"
                [class.btn-sm]="isMobile"
                [class.active]="prices.sel === 12"
              >
                Anual
              </button>
              <button
                type="button"
                (click)="changePricing(6)"
                class="btn pricing-time btn-light"
                [class.btn-sm]="isMobile"
                [class.active]="prices.sel === 6"
              >
                Semestral
              </button>
              <button
                type="button"
                (click)="changePricing(3)"
                class="btn pricing-time btn-light"
                [class.btn-sm]="isMobile"
                [class.active]="prices.sel === 3"
              >
                Trimestral
              </button>
              <button
                type="button"
                (click)="changePricing(1)"
                class="btn pricing-time btn-light"
                [class.btn-sm]="isMobile"
                [class.active]="prices.sel === 1"
              >
                Mensual
              </button>
            </div>
          </div>
          <div class="text-center mt-3">
            <div
              class="badge badge-pill badge-marketing badge-sm"
              [class.badge-light]="prices.sel == 1"
              [class.badge-yellow]="prices.sel == 3"
              [class.badge-blue]="prices.sel == 6"
              [class.badge-success]="prices.sel == 12"
            >
              {{ prices[prices.sel][3] }}
            </div>
            <div class="pricing-price"><sup>Bs.</sup>{{ prices[prices.sel][0] }}<span class="pricing-price-period">/mes</span></div>
            <div class="pricing-price-small">
              <sup>Bs.</sup>{{ prices[prices.sel][4] }}<span class="pricing-price-period"> en total</span>
            </div>
          </div>
          <ul class="fa-ul pricing-list mt-2">
            <li class="pricing-list-item">
              <span class="fa-li"><i class="fa fa-check-circle text-teal"></i></span>
              <span class="text-dark">Menú</span>
            </li>
            <li class="pricing-list-item">
              <span class="fa-li"><i class="fa fa-check-circle text-teal"></i></span><span class="text-dark">Perfil de tu negocio</span>
            </li>
            <li class="pricing-list-item">
              <span class="fa-li"><i class="fa fa-check-circle text-teal"></i></span><span class="text-dark">Dashboard de control</span>
            </li>
            <li class="pricing-list-item">
              <span class="fa-li"><i class="fa fa-check-circle text-teal"></i></span><span class="text-dark">URL personalizada</span>
            </li>
            <li class="pricing-list-item">
              <span class="fa-li"><i class="fa fa-check-circle text-teal"></i></span><span class="text-dark"> Código QR</span>
            </li>
            <li class="pricing-list-item">
              <span class="fa-li"><i class="fa fa-check-circle text-teal"></i></span><span class="text-dark"> Impresión de pedidos</span>
            </li>
            <li class="pricing-list-item">
              <span class="fa-li"><i class="fa fa-check-circle text-teal"></i></span
              ><span class="text-dark"> Reportes en formato Excel</span>
            </li>
          </ul>
          <ul class="fa-ul pricing-list mb-0">
            <!-- <li class="pricing-list-item">
              <span class="fa-li"><i class="fa fa-check-circle text-teal"></i></span
              ><span class="text-dark">Recibe hasta <b>500 pedidos / mes</b> por sucursal</span>
            </li>
            <li class="pricing-list-item">
              <span class="fa-li"><i class="fa fa-check-circle text-teal"></i></span
              ><span class="text-dark">Agrega hasta <b>100 productos</b> en tu menú</span>
            </li>
            <li class="pricing-list-item">
              <span class="fa-li"><i class="fa fa-check-circle text-teal"></i></span
              ><span class="text-dark">Sube hasta <b>5 imágenes </b> por producto</span>
            </li> -->
            <li class="pricing-list-item">
              <span class="fa-li"><i class="fa fa-check-circle text-teal"></i></span><span class="text-dark">Soporte técnico</span>
            </li>
          </ul>
          <div class="text-body text-sm pt-3">Pago por depósito o transferencia bancaria.</div>
          <div class="d-flex justify-content-center mt-5">
            <a
              *ngIf="!isBusiness"
              class="btn btn-primary btn-marketing rounded-pill lift lift-sm text-white"
              style="vertical-align: middle"
              (click)="register()"
              >{{ cta }}</a
            >

            <a *ngIf="isBusiness" class="btn btn-primary text-white" (click)="renewPlan()">Contratar Plan</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
