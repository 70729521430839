const injectScript = (src) => {
  const scriptEl = window.document.createElement("script");
  scriptEl.src = src;
  window.document.body.appendChild(scriptEl);
};

export function addGoogleMapsApi(googleMapsApiKey) {
  const url = "https://maps.googleapis.com/maps/api/js?key=";
  const params = "&libraries=places&language=en-US";

  const GoogleApiKey = googleMapsApiKey;

  injectScript(url + GoogleApiKey + params);
}
