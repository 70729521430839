export class BusinessStats {
  id: string;
  views: { [id: string]: number };
  orders: { [id: string]: number };
  journey: { [id: string]: number };
  'dashboard-login': { [id: string]: number };
  'internal-orders': { [id: string]: number };
  'external-orders': { [id: string]: number };

  constructor() {
    this.id = '';
    this.views = {};
    this.orders = {};
    this.journey = {};
    this['dashboard-login'] = {};
    this['internal-orders'] = {};
    this['external-orders'] = {};
  }
}
