import { Injectable, EventEmitter } from '@angular/core';

import { AnalyticsService, AnalyticEvent } from './analytics.service';
import { ImageService } from './image.service';
import { ProductService } from './product.service';

import { BusinessMenu, ImageData, MenuCategory, Product } from '@models/index';

import { BehaviorSubject } from 'rxjs';

import * as firebase from 'firebase/app';
import 'firebase/functions';

import { PROD_MODE } from '@utils/constants';
import { COMMONS, FUNCTIONS } from '@utils/index';

@Injectable()
export class BusinessMenuService {
  public update = new EventEmitter();
  public cacheUpdated = new EventEmitter();
  private allDownloaded = false;
  private businessMenus: { [id: string]: BusinessMenu } = {};

  // DASHBOARD 2.0
  public dashboardBusinessMenu: BehaviorSubject<BusinessMenu> = new BehaviorSubject(new BusinessMenu());

  constructor(private analyticsService: AnalyticsService, private productService: ProductService, private imageService: ImageService) {}

  public updateData() {
    this.businessMenus = {};
    this.allDownloaded = false;
    this.update.next(true);
  }

  // DASHBOARD 2.0
  public async getDashboardBusinessMenu(businessID: string) {
    const businessMenu = (
      await firebase.functions().httpsCallable(FUNCTIONS.BSMENU.getBusinessMenu)({
        businessID,
        PROD_MODE,
      })
    ).data as BusinessMenu;

    this.dashboardBusinessMenu.next(businessMenu);
  }

  public async updateDashboardBusinessMenu(businessMenu: BusinessMenu): Promise<{ response: boolean; data: string }> {
    if (businessMenu.id) {
      businessMenu = await this.prepareMenuForUpdate(businessMenu);

      await firebase.functions().httpsCallable(FUNCTIONS.BSMENU.updateBusinessMenu)({
        businessMenu,
        PROD_MODE,
      });

      this.getDashboardBusinessMenu(businessMenu.id);

      return { response: true, data: 'Menu actualizado' };
    }
    return {
      response: false,
      data: 'Ocurrió un error al guardar los cambios. Intenta nuevamente.',
    };
  }

  public async addProductToDashboardMenu(businessMenu: BusinessMenu, categoryIndex: number, product: Product, gallery: ImageData[]) {
    product = await this.prepareProductForUpdate(businessMenu, product, gallery);
    businessMenu.categories[categoryIndex].products.push({
      visibility: true,
      product,
    });
    this.updateDashboardBusinessMenu(businessMenu);
  }

  public async updateProductInDashboardMenu(businessMenu: BusinessMenu, categoryIndex: number, product: Product, gallery: ImageData[]) {
    product = await this.prepareProductForUpdate(businessMenu, product, gallery);

    for (let cat = 0; cat < businessMenu.categories.length; cat++) {
      if (cat !== categoryIndex) {
        businessMenu.categories[cat].products = businessMenu.categories[cat].products.filter((prod) => prod.product.id !== product.id);
      }
    }

    let found = false;
    businessMenu.categories[categoryIndex].products.forEach((prod) => {
      if (prod.product.id === product.id) {
        prod.product = product;
        found = true;
      }
    });

    if (!found) {
      businessMenu.categories[categoryIndex].products.push({
        visibility: true,
        product,
      });
    }

    this.updateDashboardBusinessMenu(businessMenu);
  }

  public async prepareProductForUpdate(businessMenu: BusinessMenu, product: Product, gallery: ImageData[]) {
    product = await this.productService.updateGallery(product, gallery, businessMenu.id);
    this.deleteUnusedImages(businessMenu, product.filesToDelete);
    delete product.filesToDelete;
    return product;
  }

  public async deleteProductFromDashboardMenu(businessMenu: BusinessMenu, productID: string) {
    businessMenu.categories.forEach((category) => {
      category.products = category.products.filter((prod) => prod.product.id !== productID);
    });
    await this.updateDashboardBusinessMenu(businessMenu);
  }

  // ...................... 2.0

  // BusinessMenu CRUD
  private async downloadAllBusinessMenus() {
    this.businessMenus = (await firebase.functions().httpsCallable(FUNCTIONS.BSMENU.getAllBusinessMenus)({ PROD_MODE })).data;
    this.allDownloaded = true;
  }

  private async downloadBusinessMenu(businessID: string) {
    const businessMenu = (
      await firebase.functions().httpsCallable(FUNCTIONS.BSMENU.getBusinessMenu)({
        businessID,
        PROD_MODE,
      })
    ).data as BusinessMenu;
    this._saveBusinessMenuInCache(businessMenu || new BusinessMenu());
  }

  public async getAllBusinessMenus(): Promise<BusinessMenu[]> {
    if (!this.allDownloaded) {
      await this.downloadAllBusinessMenus();
    }
    return COMMONS.arrFromObj(this.businessMenus) as BusinessMenu[];
  }

  public async getBusinessMenu(businessID: string, forceDownload = false): Promise<BusinessMenu> {
    if (!this.businessMenus.hasOwnProperty(businessID) || forceDownload) {
      await this.downloadBusinessMenu(businessID);
    }
    return COMMONS.deepCopy(this.businessMenus[businessID] || {});
  }

  public async deleteBusinessMenu(businessID: string) {
    const businessMenu = await this.getBusinessMenu(businessID);
    if (businessMenu.id) {
      let allImages = [];
      businessMenu.categories.forEach((category) => {
        category.products.forEach((prod) => {
          prod.product.img.gallery.forEach((img) => {
            allImages = allImages.concat(img.files);
          });
        });
      });
      this.imageService.deleteTrashFiles(allImages);
    }
    await firebase.functions().httpsCallable(FUNCTIONS.BSMENU.deleteBusinessMenu)({
      businessID,
      PROD_MODE,
    });
    this.updateData();
  }

  public async updateBusinessMenu(businessMenu: BusinessMenu, refreshData: boolean = true): Promise<{ response: boolean; data: string }> {
    if (businessMenu.id) {
      businessMenu = await this.prepareMenuForUpdate(businessMenu);
      await firebase.functions().httpsCallable(FUNCTIONS.BSMENU.updateBusinessMenu)({
        businessMenu,
        PROD_MODE,
      });
      this.logBusinessMenuAnalytics(businessMenu);
      if (refreshData) {
        this.updateData();
      }
      return { response: true, data: 'Menu actualizado' };
    }
    return {
      response: false,
      data: 'Ocurrió un error al guardar los cambios. Intenta nuevamente.',
    };
  }

  // MENU PRODUCT UPDATE

  public async addProductToMenu(
    businessMenu: BusinessMenu,
    categoryIndex: number,
    product: Product,
    gallery: ImageData[],
    softLoading: boolean = false
  ) {
    product = await this.productService.updateGallery(product, gallery, businessMenu.id);
    this.deleteUnusedImages(businessMenu, product.filesToDelete);
    delete product.filesToDelete;
    businessMenu.categories[categoryIndex].products.push({
      visibility: true,
      product,
    });
    this.updateBusinessMenu(businessMenu, !softLoading);
  }

  public async updateProductInMenu(
    businessMenu: BusinessMenu,
    categoryIndex: number,
    product: Product,
    gallery: ImageData[],
    softLoading: boolean = false
  ) {
    product = await this.productService.updateGallery(product, gallery, businessMenu.id);
    this.deleteUnusedImages(businessMenu, product.filesToDelete);
    delete product.filesToDelete;
    businessMenu.categories[categoryIndex].products.forEach((prod) => {
      if (prod.product.id === product.id) {
        prod.product = product;
      }
    });
    this.updateBusinessMenu(businessMenu, !softLoading);
  }

  public async deleteUnusedImages(businessMenu: BusinessMenu, filesToDelete: string[]) {
    let allImages = [];
    businessMenu.categories.forEach((category) => {
      category.products.forEach((prod) => {
        prod.product.img.gallery.forEach((img) => {
          allImages = allImages.concat(img.files);
        });
      });
    });
    const countOccurrences = (arr, val) => arr.reduce((a, v) => (v === val ? a + 1 : a), 0);
    filesToDelete = filesToDelete.filter((file) => countOccurrences(allImages, file) <= 1);
    this.imageService.deleteTrashFiles(filesToDelete);
  }

  public async deleteProduct(businessMenu: BusinessMenu, productID: string, softLoading = false) {
    businessMenu.categories.forEach((category) => {
      category.products = category.products.filter((prod) => prod.product.id !== productID);
    });
    await this.updateBusinessMenu(businessMenu, !softLoading);
  }

  private logBusinessMenuAnalytics(businessMenu: BusinessMenu) {
    const data = this.prepareDataForAnalytics(businessMenu);
    this.analyticsService.logEvent(AnalyticEvent.UPDATE_MENU, data);
  }

  private prepareDataForAnalytics(businessMenu: BusinessMenu): any {
    let categories = 0;
    let products = 0;
    let productImages = 0;
    businessMenu.categories.forEach((category: MenuCategory) => {
      categories++;
      category.products.forEach((prod: { product: Product; visibility: boolean }) => {
        const product = prod.product;
        products++;
        if (product.img.gallery) {
          productImages += product.img.gallery.length;
        }
      });
    });
    return { categories, products, productImages };
  }

  public async prepareMenuForUpdate(businessMenu: BusinessMenu): Promise<BusinessMenu> {
    businessMenu.categories.forEach((category: MenuCategory) => {
      category.products.forEach((prod: any) => {
        prod.product.description = COMMONS.stringToHTML(prod.product.description);
      });
    });

    for (const i in businessMenu.categories) {
      businessMenu.categories[i] = {
        ...businessMenu.categories[i],
        products: businessMenu.categories[i].products.filter(
          (prod: { product: Product; visibility: boolean }) => prod.product.id !== 'new'
        ),
      };
    }

    return businessMenu;
  }

  public async createBusinessMenu(businessMenu: BusinessMenu): Promise<{ response: boolean; data: string }> {
    await firebase.functions().httpsCallable(FUNCTIONS.BSMENU.createBusinessMenu)({
      businessMenu,
      PROD_MODE,
    });
    this.updateData();
    return { response: true, data: 'Menu creado' };
  }

  public _saveBusinessMenuInCache(businessMenu: BusinessMenu): void {
    this.businessMenus[businessMenu.id] = businessMenu;
    this.cacheUpdated.next(true);
  }

  public async menuIsShareable(businessID: string): Promise<boolean> {
    const businessMenu = await this.getBusinessMenu(businessID);
    return businessMenu.categories.length > 0 && businessMenu.categories[0].products.length > 0;
  }
}
