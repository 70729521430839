import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnalyticsService, AnalyticEvent, AuthService } from '@services/index';
import { CONSTANTS, COMMONS } from '@utils/index';
import { PlanPaymentModalComponent } from '../plan-payment-modal/plan-payment-modal.component';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
})
export class PricingComponent implements OnInit {
  isMobile = COMMONS.isMobile();
  cta = CONSTANTS.CALL_TO_ACTION;
  isBusiness: boolean;

  prices = {
    sel: 12,
    locations: 1,
    1: [89, '', null, 'Plan Mensual', 89],
    3: [83, '-7%', null, 'Plan Trimestral', 83 * 3],
    6: [80, '-10%', null, 'Plan Semestral', 80 * 6 - 1],
    12: [75, '-16%', null, 'Plan Anual', 75 * 12 - 1],
  };

  constructor(
    public router: Router,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.isBusiness = !!this.authService.getUserUid();
  }

  public changePricing(type: number) {
    this.prices.sel = type;
  }

  public goTo(page: string) {
    this.router.navigate(['/' + page]);
  }

  public register() {
    this.analyticsService.logEvent(AnalyticEvent.OPEN_REGISTER, {
      section: 'pricing',
    });
    this.goTo('register');
  }

  public renewPlan() {
    const modalRef = this.modalService.open(PlanPaymentModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    });

    const selectedPlan = {
      name: this.prices[this.prices.sel][3],
      duration: this.prices.sel,
      price: this.prices[this.prices.sel][4],
      monthlyPrice: this.prices[this.prices.sel][0],
    };

    modalRef.componentInstance.selectedPlan = selectedPlan;
  }
}
