import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';

import {
  AdminAuthGuardService,
  BusinessAuthGuardService,
} from '@services/index';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/landing/landing.module').then((m) => m.LandingModule),
    pathMatch: 'full',
  },
  {
    path: 'service',
    loadChildren: () =>
      import('./modules/alternative-landing/alternative-landing.module').then(
        (m) => m.AlternativeLandingModule
      ),
  },
  {
    path: 'login',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'pricing',
    loadChildren: () =>
      import('./modules/pricing-page/pricing-page.module').then(
        (m) => m.PricingPageModule
      ),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./modules/register/register.module').then(
        (m) => m.RegisterModule
      ),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AdminAuthGuardService],
  },
  {
    path: 'business',
    loadChildren: () =>
      import('./modules/business/business.module').then(
        (m) => m.BusinessModule
      ),
    canActivate: [BusinessAuthGuardService],
  },
  {
    path: ':id',
    loadChildren: () =>
      import('./modules/menu/menu.module').then((m) => m.MenuModule),
  },
  {
    path: 'order/:id',
    loadChildren: () =>
      import('./modules/order/order.module').then((m) => m.OrderModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
