import { Injectable } from '@angular/core';

@Injectable()
export class NotificationService {
  public requestPermission() {
    if (this.isSupported() && Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
  }

  public newOrder() {
    if (this.isSupported()) {
      if (Notification.permission !== 'granted') Notification.requestPermission();
      else {
        this.beep();
        const notification = new Notification('¡Nuevo pedido!', {
          icon: 'https://firebasestorage.googleapis.com/v0/b/yappa-bo.appspot.com/o/dev%2Fmeta.jpg?alt=media',
          body: 'Recibiste un nuevo pedido.',
        });
      }
    }
  }

  public beep() {
    var snd = new Audio('assets/mp3/notification.mp3');
    snd.play();
  }

  private isSupported = () => 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;
}
