<div class="modal-header">
  <h4 class="modal-title">Contratar plan</h4>
  <button type="button" class="close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <h2 class="mb-3">Plan seleccionado</h2>
  <div class="alert selected-plan border rounded p-3 mt-1 mb-3 mx-2">
    <b>Nombre:</b> {{ selectedPlan.name }}<br />
    <b>Duración:</b> {{ selectedPlan.duration }} {{ selectedPlan.duration === 1 ? "mes" : "meses" }}<br />
    <b>Precio:</b> Bs {{ selectedPlan.price }}
    <span *ngIf="selectedPlan.price != selectedPlan.monthlyPrice">(equivalente a Bs {{ selectedPlan.monthlyPrice }} por mes)</span>
    <br />
  </div>

  <p class="text-center mb-4">Para contratar el plan, haz el pago por el monto total y envíanos el comprobante via WhatsApp.</p>

  <h2 class="mb-3">Pago</h2>

  <div class="d-flex">
    <div class="col-auto px-2">
      <div
        class="card payment-method-card pointer"
        (click)="selectPaymentMethod('qr')"
        [ngClass]="{ 'border border-primary font-weight-bold': paymentMethod === 'qr' }"
      >
        <div class="card-body d-flex justify-content-center align-items-center">
          <img src="assets/img/qr-icon.png" class="mr-2" width="24" height="24" />
          Pago con QR
        </div>
      </div>
    </div>
    <div class="col-auto px-2">
      <div
        class="card payment-method-card pointer"
        (click)="selectPaymentMethod('transfer')"
        [ngClass]="{ 'border border-primary font-weight-bold': paymentMethod === 'transfer' }"
      >
        <div class="card-body d-flex justify-content-center align-items-center">
          <i-feather name="credit-card" class="mr-2"></i-feather>Transferencia bancaria
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="paymentMethod === 'transfer'" class="my-4">
    <div class="border rounded p-3 mt-1 mb-3 mx-2">
      <b>Banco:</b> Bisa<br />
      <b>Número de Cuenta:</b> 4501674015 (Bs)<br />
      <b>Nombre:</b> Diego Araujo Justiniano<br />
      <b>Monto total:</b> Bs {{ selectedPlan.price }}<br />
    </div>
  </div>

  <div *ngIf="paymentMethod === 'qr'" class="my-4">
    <div class="d-flex flex-column justify-content-center align-items-center qr-code">
      <img [src]="'assets/img/qr/qr-' + selectedPlan.price + '.png'" style="width: 230px; height: 230px" />
      <button *ngIf="isMobile" class="btn btn-primary" (click)="downloadQR('assets/img/qr/qr-' + selectedPlan.price + '.png')">
        Descargar imagen
      </button>
    </div>
  </div>

  <div class="alert alert-warning mt-2">
    Cualquiera sea el método de pago que escojas, no olvides enviarnos el comprobante de pago por WhatsApp para que activemos tu nuevo plan
    cuanto antes.
    <br />
    <div class="text-center mt-3">
      <button class="btn btn-success" (click)="contactUs()">
        <i class="fa fa-whatsapp mr-2"></i>
        Enviar comprobante de pago
      </button>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="w-100 d-flex justify-content-between align-items-center">
    <button class="btn btn-light" (click)="closeModal()">Cerrar</button>
    <div class="text-center">
      ¿Necesitas ayuda?
      <a class="icon-list-social-link mx-2 text-success pointer" (click)="contactUs()">
        <i class="fa fa-whatsapp"></i>
        Contáctanos
      </a>
    </div>
  </div>
</div>
