import { Injectable } from '@angular/core';

import { ImageData, Product } from '@models/index';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { base64ToFile } from 'ngx-image-cropper';

@Injectable()
export class ImageService {
  private storageRoot = 'dev/';
  // private storageRoot = 'prod/';

  serviceParams = {
    jpg: { contentType: 'image/jpeg', cacheControl: 'public,max-age=86400' },
    png: { contentType: 'image/png', cacheControl: 'public,max-age=86400' },
  };

  public async uploadImage(
    imageData: ImageData,
    basePath: string
  ): Promise<{ desktop: string; mobile: string }> {
    const path = this.storageRoot + basePath;
    const desktopImg = await this.resizeImage(
      { ...imageData },
      imageData.width.desktop
    );
    const mobileImg = await this.resizeImage(
      { ...imageData },
      imageData.width.mobile
    );
    const res = await Promise.all([
      this.upload(desktopImg, path, imageData.id),
      this.upload(mobileImg, path, `${imageData.id}-mobile`),
    ]);
    return { desktop: res[0], mobile: res[1] };
  }

  private async upload(
    imageData: ImageData,
    path: string,
    fileName: string
  ): Promise<string> {
    return new Promise<string>((resolve) => {
      const base64Image = imageData.new;
      const imagesRef = firebase.storage().ref().child(path);
      imagesRef
        .child(fileName)
        .putString(
          base64Image,
          'data_url',
          this.serviceParams[imageData.format]
        )
        .then((snapshot) => resolve(snapshot.ref.getDownloadURL()))
        .catch((error) => resolve(''));
    });
  }

  private async resizeImage(
    imageData: ImageData,
    width: number
  ): Promise<ImageData> {
    const img = await this.loadImageAndGetDimensions(imageData.new);
    const relation = img.width / img.height;
    const height = width / relation;
    if (width <= img.width && height <= img.height) {
      imageData.new = this.resizeDataURL(img, width, height, imageData.format);
    } else {
      imageData.new = this.resizeDataURL(
        img,
        img.width,
        img.height,
        imageData.format
      );
    }
    return imageData;
  }

  private loadImageAndGetDimensions(src: any): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        resolve(img);
      };
      img.onerror = reject;
      img.src = src;
    });
  }

  private resizeDataURL(
    img: any,
    wantedWidth: number,
    wantedHeight: number,
    format: string
  ) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = wantedWidth;
    canvas.height = wantedHeight;
    ctx.drawImage(img, 0, 0, wantedWidth, wantedHeight);
    return canvas.toDataURL('image/' + format);
  }

  public async deleteTrashFiles(files: string[]) {
    const storageRef = firebase.storage().ref();
    const deleteFilePromises = [];
    files.forEach((file) => {
      deleteFilePromises.push(
        storageRef.child(this.storageRoot + file).delete()
      );
    });
    await Promise.all(deleteFilePromises);
  }
}
