import { environment } from '../../../environments/environment';
export const PROD_MODE = environment.production;
// export const PROD_MODE = true;

export const BASE_DOMAIN = PROD_MODE ? 'https://menu-7.com/' : 'https://dev.menu-7.com/';
// export const BASE_DOMAIN = 'localhost:4200/'; // ONLY FOR DEV

export const CALL_TO_ACTION = 'Regístrate gratis';
export const ALTERNATIVE_CALL_TO_ACTION = 'Comenzar ahora';
export const ALTERNATIVE_CONTACT_FORM = 'https://forms.gle/SX6qa69Hp2WjYrZF7';
export const TRIAL_DURATION_DAYS = 30;

export const googleMapsLoaded = () => {
  GOOGLE_MAPS_LOADED = true;
};
export let GOOGLE_MAPS_LOADED = false;

function sortByName(arr: any[]): any[] {
  return arr.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  });
}

export const BUSINESS_TYPES = sortByName([
  { id: 'BAR', name: 'Bar' },
  { id: 'CAFETERIA', name: 'Cafetería' },
  { id: 'FOODTRUCK', name: 'Carrito de comida' },
  { id: 'ICECREAM', name: 'Heladería' },
  { id: 'BAKERY', name: 'Repostería' },
  { id: 'PIZZA', name: 'Pizzería' },
  { id: 'RESTAURANT', name: 'Restaurante' },
  { id: 'BUSINESS', name: 'Negocio' },
  { id: 'STORE', name: 'Tienda' },
  { id: 'ONLINE_STORE', name: 'Tienda online' },
  { id: 'SERVICE', name: 'Servicio' },
]);

export const BUSINESS_TYPE_NAMES = {
  BAR: 'Bar',
  CAFETERIA: 'Cafetería',
  FOODTRUCK: 'Carrito de comida',
  ICECREAM: 'Heladería',
  BAKERY: 'Repostería',
  PIZZA: 'Pizzería',
  RESTAURANT: 'Restaurante',
  BUSINESS: 'Negocio',
  STORE: 'Tienda',
  ONLINE_STORE: 'Tienda online',
  SERVICE: 'Servicio',
};

export const BUSINESS_CITIES = sortByName([
  { id: 'CBA', name: 'Cochabamba' },
  { id: 'SCZ', name: 'Santa Cruz' },
  { id: 'LPZ', name: 'La Paz' },
  { id: 'ALT', name: 'El Alto' },
  { id: 'ORU', name: 'Oruro' },
  { id: 'POT', name: 'Potosí' },
  { id: 'CHU', name: 'Chuquisaca' },
  { id: 'TJA', name: 'Tarija' },
  { id: 'PAN', name: 'Pando' },
  { id: 'BEN', name: 'Beni' },
]);

export const BUSINESS_CITY_NAMES = {
  CBA: 'Cochabamba',
  SCZ: 'Santa Cruz',
  LPZ: 'La Paz',
  ALT: 'El Alto',
  ORU: 'Oruro',
  POT: 'Potosí',
  CHU: 'Chuquisaca',
  TJA: 'Tarija',
  PAN: 'Pando',
  BEN: 'Beni',
};

export const ORDER_METHODS_SHORT_NAMES = {
  delivery: 'Entrega',
  pickup: 'Recojo',
  table: 'En el local',
};

export const ORDER_METHODS_NAMES = {
  delivery: 'Entrega (delivery)',
  pickup: 'Recojo (pickup)',
  table: 'En el local (mesa)',
};

export const PAYMENT_METHODS_NAMES = {
  cash: 'Efectivo',
  card: 'Tarjeta',
  transfer: 'Transferencia',
};

export const ORDER_STATUS_NAMES = {
  new: 'Nuevo',
  pending: 'Visto',
  rejected: 'Cancelado',
  terminated: 'Terminado',
};

export const PLAN_NAMES = {
  trial: 'Prueba',
  standard: 'Estándar',
};

export const PLAN_DURATION_NAMES = {
  7: '7 días',
  30: 'Mensual',
  90: 'Trimestral',
  180: 'Semestral',
  360: 'Anual',
};

export const SCHEDULE_HOURS = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
  '24:00',
  '00:30',
  '01:30',
  '02:30',
  '03:30',
  '04:30',
  '05:30',
  '06:30',
  '07:30',
  '08:30',
  '09:30',
  '10:30',
  '11:30',
  '12:30',
  '13:30',
  '14:30',
  '15:30',
  '16:30',
  '17:30',
  '18:30',
  '19:30',
  '20:30',
  '21:30',
  '22:30',
  '23:30',
].sort();

export const THEME_COLORS = [
  '#ffaa00',
  '#fed330',
  '#20bf6b',
  '#26de81',
  '#2bcbba',
  '#0fb9b1',
  '#2d98da',
  '#45aaf2',
  '#778ca3',
  '#4b6584',
  '#4b7bec',
  '#3867d6',
  '#8854d0',
  '#a55eea',
  '#E2598F',
  '#b71540',
  '#ef3f3b',
  '#fc5c65',
  '#fd9644',
  '#fa8231',
  '#933709',
  '#59260B',
];

export const BUSINESS_NAME = '';
