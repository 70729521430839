import { Injectable } from '@angular/core';
import { BusinessData, PublicBusinessData } from '@models/index';

import * as firebase from 'firebase/app';
import 'firebase/functions';

import { COMMONS, FUNCTIONS } from '@utils/index';
import { PROD_MODE } from '@utils/constants';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BusinessDataService {
  public businessData: BehaviorSubject<BusinessData> = new BehaviorSubject(new BusinessData());

  constructor() {}

  public async getAllBusinessData(): Promise<BusinessData[]> {
    const allBusinessData = (
      await firebase.functions().httpsCallable(FUNCTIONS.BSDATA.getAllBusinessData)({
        PROD_MODE,
      })
    ).data;
    return Object.values(allBusinessData) as BusinessData[];
  }

  public async getBusinessData(businessID: string): Promise<BusinessData> {
    let businessData = (
      await firebase.functions().httpsCallable(FUNCTIONS.BSDATA.getBusinessData)({
        businessID,
        PROD_MODE,
      })
    ).data as BusinessData;

    businessData = {
      ...businessData,
      profile: {
        ...businessData.profile,
        description: COMMONS.HTMLToString(businessData.profile.description),
        abstract: COMMONS.HTMLToString(businessData.profile.abstract),
      },
    } as BusinessData;

    COMMONS.customizeColor(businessData.profile.color);

    this.businessData.next(businessData);
    return businessData;
  }

  public async updateBusinessData(businessData: BusinessData): Promise<{ response: boolean; data: string }> {
    if (businessData.id) {
      businessData = COMMONS.deepCopy({
        ...businessData,
        profile: {
          ...businessData.profile,
          description: COMMONS.stringToHTML(businessData.profile.description),
          abstract: COMMONS.stringToHTML(businessData.profile.abstract),
          files: COMMONS.removeDuplicates(businessData.profile.files),
        },
      }) as BusinessData;

      await firebase.functions().httpsCallable(FUNCTIONS.BSDATA.updateBusinessData)({
        businessData,
        PROD_MODE,
      });

      this.getBusinessData(businessData.id);
      return { response: true, data: 'Cambios guardados' };
    }
    return {
      response: false,
      data: 'Ocurrió un error al guardar los cambios. Intenta nuevamente.',
    };
  }

  public async getBusinessDataFromCustomID(customID: string): Promise<BusinessData> {
    return (
      await firebase.functions().httpsCallable(FUNCTIONS.BSDATA.getBusinessDataFromCustomID)({
        customID,
        PROD_MODE,
      })
    ).data as BusinessData;
  }

  public async getPublicBusinessDataFromCustomID(customID: string): Promise<PublicBusinessData> {
    return (
      await firebase.functions().httpsCallable(FUNCTIONS.BSDATA.getPublicBusinessDataFromCustomID)({
        customID,
        PROD_MODE,
      })
    ).data as PublicBusinessData;
  }
}
