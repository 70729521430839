export class Plan {
  id: string;
  businessID: string;
  name: 'trial' | 'standard';
  price: number;
  startDate: number;
  endDate: number;
  duration: number;

  // TO DO: Delete "locations", "orders", "products" property after Migration to V2
  locations?: number;
  orders?: number;
  products?: number;

  createdAt: number;
  updatedAt: number;

  constructor() {
    this.id = '';
    this.businessID = '';
    this.name = 'trial';
    this.price = 0;
    this.startDate = 0;
    this.duration = 0;
    this.endDate = 0;
    this.createdAt = 0;
    this.updatedAt = 0;
  }
}
