export class User {
  uid: string;
  type: 'admin' | 'business' | 'customer' | '';
  email: string;
  createdAt: number;
  updatedAt: number;

  constructor() {
    this.uid = '';
    this.type = '';
    this.email = '';
    this.createdAt = 0;
    this.updatedAt = 0;
  }
}
