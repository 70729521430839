export class OrderItem {
  amount: number;
  product: {
    name: string;
    img?: { profile?: { desktop: string; mobile: string } };
  };
  options: {
    name: string;
    selected: {
      name: string;
      value: number;
    }[];
  }[];
  notes: string;
  price: number;
  totalPrice: number;

  constructor() {
    this.amount = 1;
    this.product = {
      name: '',
    };
    this.options = [];
    this.notes = '';
    this.price = 0;
    this.totalPrice = 0;
  }
}

export class Order {
  id: string;
  businessID: string;
  location: {
    name: string;
    address: string;
    phone: string;
    available: boolean;
  };
  method: '' | 'delivery' | 'pickup' | 'table';
  payment: '' | 'cash' | 'card' | 'transfer';
  address: string;
  address2?: string;
  lat?: number;
  lng?: number;
  table: string;
  notes: string;
  client: {
    name: string;
    phone: string;
  };
  bill: {
    name: string;
    nit: string;
  };
  items: OrderItem[];
  sent: boolean;
  totalPrice: number;
  status: 'new' | 'pending' | 'rejected' | 'terminated';
  createdAt: number;
  updatedAt: number;

  constructor() {
    this.id = '';
    this.businessID = '';
    this.location = {
      name: '',
      address: '',
      phone: '',
      available: true,
    };
    this.method = '';
    this.payment = '';
    this.address = '';
    this.address2 = '';
    this.lat = null;
    this.lng = null;
    this.table = '';
    this.notes = '';
    this.client = {
      name: '',
      phone: '',
    };
    this.bill = {
      name: '',
      nit: '',
    };
    this.items = [];
    this.sent = false;
    this.totalPrice = 0;
    this.status = 'new';
    this.createdAt = 0;
    this.updatedAt = 0;
  }
}
