import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { BusinessData, BusinessProfile, PublicBusinessData, Order, OrderItem } from '@models/index';

import * as firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/firestore';

import { COMMONS, FUNCTIONS } from '@utils/index';
import { PROD_MODE } from '@utils/constants';

@Injectable()
export class OrderService {
  allOrders: Order[] = [];
  businessOrders: any = {};
  currentOrder: Order = new Order();
  cachedOrders: { [id: string]: Order } = {};

  order: BehaviorSubject<Order> = new BehaviorSubject(this.currentOrder);

  constructor() {}

  async generateNewOrder(businessData: BusinessData | PublicBusinessData) {
    this.currentOrder = new Order();
    this.currentOrder.id = await this.getOrderID();
    this.currentOrder.businessID = businessData.id;
    if (businessData.profile.locations.length) {
      this.currentOrder.location = businessData.profile.locations[0];
    }
    this.updateOrder();
  }

  public addItemToCart(orderItem: OrderItem): void {
    this.currentOrder.items.push(orderItem);
    this.updateOrder();
  }

  public removeItemFromCart(itemIndex: number) {
    this.currentOrder.items.splice(itemIndex, 1);
    this.updateOrder();
  }

  public updateOrder() {
    const itemPrices = this.currentOrder.items.map((orderItem: OrderItem) => orderItem.totalPrice);
    this.currentOrder.totalPrice = 0;
    if (itemPrices.length) {
      this.currentOrder.totalPrice = itemPrices.reduce((a, b) => a + b);
    }
    this.order.next(this.currentOrder);
  }

  // BE interactions

  private async downloadAllOrders() {
    const allOrders = (await firebase.functions().httpsCallable(FUNCTIONS.ORDER.getAllBusinessOrders)({ PROD_MODE })).data;
    this.allOrders = Object.values(allOrders);
  }

  public async getAllOrders() {
    if (!this.allOrders.length) {
      await this.downloadAllOrders();
    }
    return this.allOrders;
  }

  private async downloadOrder(orderID: string): Promise<void> {
    let order = (
      await firebase.functions().httpsCallable(FUNCTIONS.ORDER.getOrder)({
        orderID,
        PROD_MODE,
      })
    ).data as Order;

    if (order === undefined) {
      order = new Order();
    } else {
      this.cachedOrders[order.id] = order || new Order();
    }
  }

  public async getOrder(orderID: string): Promise<Order> {
    if (!this.cachedOrders.hasOwnProperty(orderID)) {
      await this.downloadOrder(orderID);
    }
    const order = this.cachedOrders[orderID];
    if (order === undefined) {
      return new Order();
    }
    return COMMONS.deepCopy(order);
  }

  public async createOrder(order: Order): Promise<{ response: boolean; data: string; orderID: string }> {
    this.saveOrderInCache(order);
    await this.saveNewOrder(order);
    return { response: true, data: 'Pedido creado', orderID: order.id };
  }

  public async saveOrder(order: Order, isNew = false): Promise<{ response: boolean; data: string; orderID: string }> {
    const res = await firebase.functions().httpsCallable(FUNCTIONS.ORDER.updateOrder)({ order, PROD_MODE });
    this.businessOrders = {};
    return { response: true, data: 'Pedido creado', orderID: res.data };
  }

  public async deleteOrder(orderID: string) {
    await firebase.functions().httpsCallable(FUNCTIONS.ORDER.deleteOrder)({
      orderID,
      PROD_MODE,
    });
  }

  public async saveNewOrder(order: Order, isNew = false): Promise<{ response: boolean; data: string; orderID: string }> {
    const res = await firebase.functions().httpsCallable(FUNCTIONS.ORDER.createOrder)({ order, PROD_MODE });
    return { response: true, data: 'Pedido creado', orderID: res.data };
  }

  private async downloadBusinessOrdersFromInterval(businessID: string, start: number, end: number): Promise<void> {
    const orders = (
      await firebase.functions().httpsCallable(FUNCTIONS.ORDER.getBusinessOrdersFromInterval)({
        businessID,
        start,
        end,
        PROD_MODE,
      })
    ).data as Order[];
    if (!this.businessOrders.hasOwnProperty(businessID)) {
      this.businessOrders[businessID] = {};
    }
    if (!this.businessOrders[businessID].hasOwnProperty(start)) {
      this.businessOrders[businessID][start] = {};
    }
    this.businessOrders[businessID][start][end] = orders;
  }

  public async getBusinessOrders(businessID: string): Promise<Order[]> {
    const orders = (
      await firebase.functions().httpsCallable(FUNCTIONS.ORDER.getAllOrdersFromBusiness)({
        businessID,
        PROD_MODE,
      })
    ).data as Order[];
    return orders;
  }

  public async getBusinessOrdersFromInterval(businessID: string, start: number, end: number, forceDownload = false): Promise<Order[]> {
    const needToDownload = !(
      this.businessOrders.hasOwnProperty(businessID) &&
      this.businessOrders[businessID].hasOwnProperty(start) &&
      this.businessOrders[businessID][start].hasOwnProperty(end)
    );
    if (needToDownload || forceDownload) {
      await this.downloadBusinessOrdersFromInterval(businessID, start, end);
    }
    return COMMONS.deepCopy(this.businessOrders[businessID][start][end]);
  }

  private saveOrderInCache(order: Order): void {
    this.cachedOrders[order.id] = order;
  }

  private async getOrderID(): Promise<string> {
    return (
      await firebase.functions().httpsCallable(FUNCTIONS.ORDER.getId)({
        PROD_MODE,
      })
    ).data;
  }

  public cleanCache() {
    this.cachedOrders = {};
  }

  public async deleteAllBusinessOrders(businessID: string) {
    await firebase.functions().httpsCallable(FUNCTIONS.ORDER.deleteBusinessOrders)({
      businessID,
      PROD_MODE,
    });
  }

  public listenToNewOrders(businessID: string): Observable<any> {
    return new Observable((observer) => {
      const unsubscribe = firebase
        .firestore()
        .collection('orders')
        .where('businessID', '==', businessID)
        .onSnapshot(() => observer.next(true));
      return () => {
        unsubscribe();
      };
    });
  }
}
