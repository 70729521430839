export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBo3Zoj0cGUnYrSJS3hjeMqrBW534y8r-4',
    authDomain: 'menu7-dev.firebaseapp.com',
    databaseURL: 'https://menu7-dev.firebaseio.com',
    projectId: 'menu7-dev',
    storageBucket: 'menu7-dev.appspot.com',
    appId: '1:579656382994:web:7f7ac1a3b147d6ef7bf6cb',
    measurementId: 'G-DJJ43FLPPB',
  },
  googleMapsApiKey: 'AIzaSyBo3Zoj0cGUnYrSJS3hjeMqrBW534y8r-4',
};
