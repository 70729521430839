import * as Chart from 'chart.js';

export function initVisitsChart(
  labels: string[],
  register: number[],
  repeated: number[]
) {
  const ctx = document.getElementById('visitsChart');
  const myBarChart = new Chart(ctx, {
    type: 'bar',
    data: {
      labels: labels,
      datasets: [
        {
          label: 'Socios frecuentes',
          backgroundColor: 'rgba(2, 117, 216, 1)',
          hoverBackgroundColor: 'rgba(2, 117, 216, 0.9)',
          data: repeated,
        },
        {
          label: 'Socios nuevos',
          backgroundColor: 'rgba(240, 173, 78, 1)',
          hoverBackgroundColor: 'rgba(240, 173, 78, 0.8)',
          data: register,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 10,
          right: 25,
          top: 25,
          bottom: 0,
        },
      },
      scales: {
        xAxes: [
          {
            gridLines: { display: false, drawBorder: false },
            ticks: { maxTicksLimit: 6 },
            maxBarThickness: 25,
            stacked: true,
          },
        ],
        yAxes: [
          {
            ticks: { min: 0, maxTicksLimit: 5, padding: 10 },

            gridLines: {
              color: 'rgb(234, 236, 244)',
              zeroLineColor: 'rgb(234, 236, 244)',
              drawBorder: false,
              borderDash: [2],
              zeroLineBorderDash: [2],
            },
            stacked: true,
          },
        ],
      },
      legend: { position: 'bottom' },
      tooltips: {
        titleMarginBottom: 10,
        titleFontColor: '#6e707e',
        titleFontSize: 14,
        backgroundColor: 'rgb(255,255,255)',
        bodyFontColor: '#858796',
        borderColor: '#dddfeb',
        borderWidth: 1,
        xPadding: 15,
        yPadding: 15,
        displayColors: true,
        caretPadding: 10,
      },
    },
  });
}
