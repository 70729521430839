import { AnalyticEvent, AnalyticsService } from './analytics.service';
import { Injectable } from '@angular/core';
import { BusinessAccount, BusinessProfile } from '@models/index';

import * as firebase from 'firebase/app';
import 'firebase/functions';

import { FUNCTIONS } from '@utils/index';
import { PROD_MODE } from '@utils/constants';

@Injectable()
export class BusinessService {
  constructor(private analyticsService: AnalyticsService) {}

  public async createBusiness(
    businessAccount: BusinessAccount,
    businessProfile: BusinessProfile,
    password: string
  ): Promise<{ uid: string; error: boolean; errorMsg?: string }> {
    const res = (
      await firebase.functions().httpsCallable(FUNCTIONS.BUSINESS.createBusiness)({
        email: businessAccount.email,
        password,
        businessAccount,
        businessProfile,
        PROD_MODE,
      })
    ).data;

    if (res.uid) {
      const businessID = res.uid;
      this.logCreateBusinessEvent(businessID);
    }

    return res;
  }

  public async deleteBusiness(businessID: string): Promise<{ response: boolean; data: string }> {
    await firebase.functions().httpsCallable(FUNCTIONS.BUSINESS.deleteBusiness)({ businessID, PROD_MODE });
    return { response: true, data: 'Negocio eliminado' };
  }

  public async validateCustomID(customID: string): Promise<boolean> {
    return (
      await firebase.functions().httpsCallable(FUNCTIONS.BUSINESS.validateCustomID)({
        customID,
        PROD_MODE,
      })
    ).data;
  }

  logCreateBusinessEvent(uid: string) {
    this.analyticsService.logEvent(AnalyticEvent.SIGN_UP, {
      uid,
      method: 'email',
      type: 'business',
    });
  }
}
