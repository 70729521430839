import { ImageData } from './image.model';

export class Product {
  id: string;
  businessID: string;
  name: string;
  searchParams: string[];
  description: string;
  img: {
    profile?: { desktop: string; mobile: string };
    gallery: { desktop: string; mobile: string; files: string[] }[];
  };
  price: number;
  options: {
    name: string;
    limit: number;
    required: boolean;
    list: { name: string; value: number }[];
    visible: boolean;
  }[];
  edited?: {
    isEdited: boolean;
    images: {
      areNewImages: boolean;
      profile?: ImageData;
    };
  };
  availableDays?: {
    mon: boolean;
    tue: boolean;
    wed: boolean;
    thu: boolean;
    fri: boolean;
    sat: boolean;
    sun: boolean;
  };
  files?: string[];
  filesToDelete?: string[];
  createdAt: number;
  updatedAt: number;

  constructor() {
    this.id = '';
    this.businessID = '';
    this.name = '';
    this.searchParams = [];
    this.description = '';
    this.img = {
      profile: { desktop: '', mobile: '' },
      gallery: [],
    };
    this.price = 0;
    this.options = [];
    this.files = [];
    this.filesToDelete = [];
    this.availableDays = {
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: true,
      sun: true,
    };
    this.createdAt = 0;
    this.updatedAt = 0;
  }
}
