import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class LoadingService {
  showLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // bgColor: BehaviorSubject<number> = new BehaviorSubject(0);

  public load(load: boolean, text: string = 'Cargando') {
    this.showLoading.next(load);
    // this.bgColor.next(this.getRandomInt());
  }

  // private getRandomInt(max = 4) {
  //   return Math.floor(Math.random() * Math.floor(max));
  // }
}
