import { Injectable } from '@angular/core';

import { Order, BusinessProfile } from '@models/index';
import * as ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import { BASE64_LOGO, CONSTANTS, COMMONS } from '@utils/index';

@Injectable()
export class ExcelService {
  blobType: string =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  constructor() {}

  public async generateOrdersReport(
    businessProfile: BusinessProfile,
    orders: Order[],
    fileName: string,
    interval: string
  ) {
    let workbook = new ExcelJS.Workbook();
    const title = `${businessProfile.name} - Menu7`;
    workbook = this.setWorkbookProperties(workbook);
    workbook = this.generateOrdersSheet(
      workbook,
      businessProfile,
      orders,
      title,
      interval
    );
    // await workbook.getWorksheet(title).protect(businessProfile.id, {});
    await this.downloadWorkbook(workbook, fileName);
  }

  private setWorkbookProperties(workbook: any) {
    workbook.creator = 'Menu7';
    workbook.created = new Date();
    workbook.modified = new Date();
    return workbook;
  }

  private generateOrdersSheet(
    workbook: any,
    businessProfile: BusinessProfile,
    orders: Order[],
    title: string,
    interval: string
  ) {
    const cols = [
      { key: 'i', header: '#' },
      { key: 'date', header: 'Fecha', width: 14 },
      { key: 'hour', header: 'Hora', width: 12 },
      { key: 'location', header: 'Sucursal', width: 17 },
      { key: 'method', header: 'Método de entrega', width: 20 },
      { key: 'payment', header: 'Método de pago', width: 20 },
      { key: 'name', header: 'Nombre', width: 27 },
      { key: 'phone', header: 'Teléfono', width: 19 },
      { key: 'price', header: 'Precio (Bs)', width: 15 },
      { key: 'status', header: 'Estado', width: 14 },
      { key: 'url', header: 'Detalle', width: 17 },
    ];
    workbook.addWorksheet(title, {
      properties: { tabColor: { argb: '00FFAA00' } },
    });
    let ws = workbook.getWorksheet(title);
    ws.getRow(6).values = cols.map((obj) => obj.header);
    ws.columns = cols.map(function (obj) {
      return { key: obj.key, width: obj.width };
    });
    ws.mergeCells('A2', 'B2');
    ws.mergeCells('C2', 'D2');
    ws.getCell('A2').value = {
      richText: [{ font: { size: 14, bold: true }, text: 'Negocio: ' }],
    };
    ws.getCell('C2').value = {
      richText: [{ font: { size: 14 }, text: `${businessProfile.name}` }],
    };
    ws.mergeCells('A3', 'B3');
    ws.mergeCells('C3', 'D3');
    ws.getCell('A3').value = {
      richText: [{ font: { size: 14, bold: true }, text: 'Pedidos entre: ' }],
    };
    ws.getCell('C3').value = {
      richText: [{ font: { size: 14 }, text: `${interval}` }],
    };
    ws.mergeCells('A4', 'B4');
    ws.mergeCells('C4', 'D4');
    ws.getCell('A4').value = {
      richText: [{ font: { size: 14, bold: true }, text: 'Generado en: ' }],
    };
    ws.getCell('C4').value = {
      richText: [
        {
          font: { size: 14 },
          text: `${COMMONS.getDisplayTextDate(
            new Date()
          )} - ${COMMONS.getDisplayTextHour(new Date())}`,
        },
      ],
    };
    const imageId2 = workbook.addImage({
      base64: BASE64_LOGO,
      extension: 'jpg',
    });
    ws.addImage(imageId2, {
      tl: { col: 9, row: 1 },
      ext: { width: 200, height: 57 },
      editAs: 'absolute',
    });
    for (let [i, val] of COMMONS.sortArrayByProperty(
      orders,
      'createdAt'
    ).entries()) {
      const order = val as Order;
      const date = new Date(order.createdAt);
      ws.addRow({
        i: i + 1,
        date: COMMONS.getDisplayTextDate(date),
        hour: COMMONS.getDisplayTextHour(date),
        location: order.location.name,
        method: CONSTANTS.ORDER_METHODS_SHORT_NAMES[order.method],
        payment: CONSTANTS.PAYMENT_METHODS_NAMES[order.payment],
        name: order.client.name,
        phone: `+591 ${order.client.phone}`,
        price: `${order.totalPrice.toFixed(2)}`,
        status: CONSTANTS.ORDER_STATUS_NAMES[order.status],
        url: {
          text: `Ver pedido`,
          hyperlink: `${CONSTANTS.BASE_DOMAIN}order/${order.id}`,
        },
      });
    }
    ws = this.styleWorksheet(ws, orders.length);
    return workbook;
  }

  private styleWorksheet(ws: any, rows: number) {
    const firstCol = 65;

    for (let i = firstCol; i < firstCol + ws.columns.length; i++) {
      for (let j = 7; j < rows + 7; j++) {
        ws.getCell(`${String.fromCharCode(i)}${j}`).style = {
          font: { size: 14 },
          border: {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00EEEEEE' },
            bgColor: { argb: '00EEEEEE' },
          },
        };
      }
    }

    const columnsToCenter = [
      'i',
      'date',
      'hour',
      'location',
      'method',
      'payment',
      'status',
      'url',
    ];
    columnsToCenter.forEach(
      (colNum) => (ws.getColumn(colNum).alignment = { horizontal: 'center' })
    );
    ws.getColumn('i').font = { size: 14, italic: true };
    ws.getColumn('url').font = {
      size: 14,
      color: { argb: '000061f2' },
      underline: true,
    };

    for (let i = firstCol; i < firstCol + ws.columns.length; i++) {
      ws.getCell(`${String.fromCharCode(i)}6`).style = {
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00FFAA00' },
          bgColor: { argb: '00FFAA00' },
        },
        alignment: { horizontal: 'center' },
        font: { size: 14, bold: true, color: { argb: '00000000' } },
        border: {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        },
      };
    }
    return ws;
  }

  private async downloadWorkbook(workbook: any, filename: string) {
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: this.blobType });
    FileSaver.saveAs(blob, filename);
  }
}
