import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { PricingComponent } from '@shared/components/pricing/pricing.component';
import { FormValidationComponent } from '@shared/components/form-validation/form-validation.component';
import { LoadingComponent } from '@shared/components/loading/loading.component';
import { IconsModule } from '@shared/icons/icons.module';
import { NgxPrintModule } from 'ngx-print';

import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';
import { SelectLocationComponent } from '@shared/components/select-location/select-location.component';

import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IconsModule,
    NgbModule,
    RouterModule,
    NgxPrintModule,
    HttpClientModule,
    HttpClientJsonpModule,
    GoogleMapsModule,
    GooglePlaceModule,
  ],
  declarations: [
    PricingComponent,
    LoadingComponent,
    FormValidationComponent,
    SelectLocationComponent,
  ],
  exports: [
    CommonModule,
    IconsModule,
    FormsModule,
    RouterModule,
    NgbModule,
    NgxPrintModule,
    FormValidationComponent,
    PricingComponent,
    LoadingComponent,
    SelectLocationComponent,
  ],
  entryComponents: [],
})
export class SharedModuleLite {}
