import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/analytics';

export enum AnalyticEvent {
  LOGIN = 'login',
  SIGN_UP = 'sign_up',
  RESET_PASSWORD = 'reset_password',
  CONTACT_US = 'contact_us',
  CREATE_CATEGORY = 'create_category',
  CREATE_PRODUCT = 'create_product',
  MENU_URL = 'menu_url',
  OPEN_MODAL_PRODUCT_OPTIONS = 'open_modal_product_options',
  PRODUCT_OPTIONS_ADDED = 'product_options_added',
  PRODUCT_TOGGLE_VISIBILITY = 'product_toggle_visibility',
  DUPLICATE_PRODUCT = 'duplicate_product',
  REORDER_CATEGORIES = 'reorder_categories',
  REORDER_PRODUCTS = 'reorder_products',
  UPDATE_MENU = 'update_menu',
  UPDATE_PROFILE = 'update_profile',
  OPEN_MENU = 'open_menu',
  OPEN_MODAL_MENU_INFO = 'open_modal_menu_info',
  MENU_INFO_MODAL_ACTION = 'menu_info_modal_action',
  OPEN_MODAL_ADD_TO_CART = 'open_modal_add_to_cart',
  ADD_TO_CART = 'add_to_cart',
  MENU_PRODUCTS_SEARCH = 'menu_products_search',
  OPEN_MODAL_CART = 'open_modal_cart',
  PLACE_ORDER = 'place_order',
  OPEN_ORDER = 'open_order',
  OPEN_REGISTER = 'open_register',
  OPEN_ALTERNATIVE_REGISTER_FORM = 'open_alternative_register_form',
  OPEN_MODAL_SEND_ORDER = 'open_modal_send_order',
  SEND_ORDER_TO_WHATSAPP = 'send_order_to_whatsapp',
  OPEN_PRICING = 'open_pricing',
  ORDER_URL_COPIED = 'order_url_copied',
  LANDING_PAGE = 'landing_page',
  MENU_TO_LANDING = 'menu_to_landing',
  MENU_DEMO = 'menu_demo',
}

@Injectable()
export class AnalyticsService {
  private analytics = firebase.analytics();

  public logEvent(name: string, opts?: any) {
    this.analytics.logEvent(name, opts);
  }

  public setUserId(uid: string) {
    this.analytics.setUserId(uid);
  }
}
