// import { PROD_MODE } from './../utils/constants';
import { Injectable } from '@angular/core';

import { BusinessDataService } from './business-data.service';
import { BusinessAccountService } from './business-account.service';
import { BusinessProfileService } from './business-profile.service';
import { BusinessMenuService } from './business-menu.service';
// import { ExcelService } from './excel.service';
// import { OrderService } from './order.service';
import { PlanService } from './plan.service';
// import { StatsService } from './stats.service';

import { BusinessAccount, BusinessMenu, BusinessProfile, Order, Product, Plan, BusinessData } from '@models/index';

import { COMMONS } from '@utils/index';
import * as firebase from 'firebase/app';
import 'firebase/functions';

@Injectable()
export class HelperService {
  constructor(
    private businessDataService: BusinessDataService,
    private businessAccountService: BusinessAccountService,
    private businessMenuService: BusinessMenuService,
    private businessProfileService: BusinessProfileService,
    private planService: PlanService
  ) {}

  public async init() {
    // console.log('<<<<< HELPER INIT >>>>>');
    // console.log('<<<<< HELPER END >>>>>');
  }

  private async updateBusinessDataModel() {
    const allBusinessData = await this.businessDataService.getAllBusinessData();

    var i = 0;
    for (let businessData of allBusinessData) {
      i++;
      console.log(`\n businessID: ${businessData.id}\n`);

      // Add the new property 'visible' to all product options:
      businessData?.menu?.categories.forEach((category) => {
        if (category.products) {
          category.products.forEach((p) => {
            p.product.options.forEach((option) => {
              if (!option.hasOwnProperty('visible')) {
                option.visible = true;
              }
            });
          });
        }
      });

      console.log('-> businessData:', businessData);

      // return;
      await this.businessDataService.updateBusinessData(businessData);
      console.log(`(${i}) - ${businessData.id} Updated!`);
    }
  }

  private async updateAllBusinessToV2() {
    const allBusinessAccounts = await this.businessAccountService.getAllBusinessAccounts();
    const allBusinessProfiles = await this.businessProfileService.getAllBusinessProfiles();
    const allBusinessMenus = await this.businessMenuService.getAllBusinessMenus();

    const businessIDs = allBusinessAccounts.map((account) => account.id);
    console.log('LOG: businessIDs', businessIDs);
    console.log('LOG: OLD ACCOUNTS: ', businessIDs.length);

    // return;
    var i = 0;
    for (let businessID of businessIDs) {
      i++;
      // const businessID = 'mKInqdFGCZV95AW7CO1wJiby8PG3';

      console.log(`\n businessID: ${businessID}\n`);
      const businessAccount = allBusinessAccounts.find((account) => account.id === businessID);
      const businessProfile = allBusinessProfiles.find((profile) => profile.id === businessID);
      const businessMenu = allBusinessMenus.find((menu) => menu.id === businessID);
      const businessPlans = await this.planService.getBusinessPlans(businessID);

      // console.log('businessAccount:', { ...businessAccount });
      // console.log('businessProfile:', { ...businessProfile });
      // console.log('businessMenu:', { ...businessMenu });
      // console.log('businessPlans:', [...businessPlans]);

      const businessData = new BusinessData();
      businessData.id = businessID;
      businessData.customID = businessProfile.customID;
      businessData['createdAt'] = businessAccount['createdAt'];
      businessData['updatedAt'] = businessAccount['updatedAt'];

      businessData.account = businessAccount;
      businessData.profile = businessProfile;
      businessData.menu = businessMenu;
      businessData.plans = COMMONS.sortArrayByProperty(businessPlans, 'startDate');

      // ------ CHECK THAT NEW PROPERTIES ARE UPDATED CORRECTY ------ :
      businessData.profile.locations = businessData.profile.locations.map((location) => {
        location.whatsAppEnabled = !!(location.phone && location.phone.length > 0);
        return location;
      });

      businessData.plans = businessData.plans.map((plan) => {
        delete plan['locations'];
        delete plan['orders'];
        delete plan['products'];
        return plan;
      });

      businessData.profile.social.tiktok = '';

      delete businessData.account['steps'];
      delete businessData.account['lastPlan'];
      delete businessData.account['journey'];
      // ------ CHECK THAT NEW PROPERTIES ARE UPDATED CORRECTY ------ :

      console.log('-> businessData:', businessData);

      // return;
      // await this.businessDataService.updateBusinessData(businessData);
      // console.log(`(${i}) - ${businessID} Updated!`);
    }
  }

  private async triggerEmailHealthCheck() {
    const res = (await firebase.functions().httpsCallable('EMAIL_healthcheck')()).data;
    console.log(res);
  }
}
