import { Injectable } from '@angular/core';

@Injectable()
export class ToastService {
  public showCustomToast(res: boolean, message: string, time: number = 5000) {
    if (res) {
      this.showSuccess(message, time);
    } else {
      this.showError(message, time);
    }
  }

  public showError(message: string, time: number = 5000) {
    const successToast = document.getElementById('errorToast');
    successToast.textContent = message;
    successToast.style.opacity = '1';
    setTimeout(() => {
      successToast.style.opacity = '0';
    }, time);
  }

  public showSuccess(message: string, time: number = 5000) {
    const successToast = document.getElementById('successToast');
    successToast.textContent = message;
    successToast.style.opacity = '1';
    setTimeout(() => {
      successToast.style.opacity = '0';
    }, time);
  }
}
