import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class BusinessAuthGuardService implements CanActivate {
  constructor(public authService: AuthService, public router: Router) {}

  canActivate(): boolean {
    // return true; // BATMAN
    const user = this.authService.getUser();
    if (user.uid) {
      if (user.type === 'business') {
        return true;
      } else {
        if (user.type === 'admin') {
          this.router.navigate(['admin']);
        }
        if (user.type === 'customer') {
          this.router.navigate(['customer']);
        }
        return false;
      }
    } else {
      this.router.navigate(['login']);
      return false;
    }
  }
}
