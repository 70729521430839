import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.scss'],
})
export class SelectLocationComponent implements OnInit {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;

  @Input() address = '';
  @Input() address2 = '';
  @Input() lat = null;
  @Input() lng = null;
  @Output() locationSelected: EventEmitter<any> = new EventEmitter(true);
  location: any;
  loaded = false;

  center = { lat: 40, lng: -20 };
  zoom = 19;
  geolocationAllowed = false;

  public googleMapOptions: google.maps.MapOptions = {
    clickableIcons: false,
    disableDefaultUI: false,
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    maxZoom: 20,
    minZoom: 7,
    gestureHandling: 'greedy',
  };

  public googleAutocompleteOptions = {
    types: ['address'],
    origin: new google.maps.LatLng(0, 0),
    fields: ['geometry', 'formatted_address'],
    componentRestrictions: { country: 'BO' },
  };

  validation = {
    address: true,
    showErrors: false,
    valid: false,
  };

  constructor(public activeModal: NgbActiveModal) {}

  async ngOnInit() {
    if (this.lat === null && this.lng === null) {
      await this.selectMyLocation();
    } else {
      this.updateMapCenter(this.lat, this.lng);
    }
  }

  updateMapCenter(lat: number, lng: number) {
    this.center = { lat, lng };
    this.zoom = 19;
  }

  public handleAddressChange(address: any) {
    const { formatted_address, geometry } = address;
    this.address = formatted_address;
    this.updateMapCenter(geometry.location.lat(), geometry.location.lng());
  }

  async selectMyLocation() {
    this.loaded = false;

    if (navigator.permissions) {
      const { state } = await navigator.permissions.query({
        name: 'geolocation',
      });
      this.geolocationAllowed = state === 'granted';
    } else {
      this.geolocationAllowed = false;
    }

    if (!this.geolocationAllowed || !navigator.geolocation) {
      this.setDefaultCenter();
      this.loaded = true;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        this.updateMapCenter(latitude, longitude);
        this.loaded = false;
      },
      (error) => {
        this.setDefaultCenter();
        this.loaded = true;
      }
    );
  }

  setDefaultCenter() {
    this.updateMapCenter(-16.9717914, -66.1331355);
    this.zoom = 7;
  }

  validate() {
    this.validation.address = !!this.address.length;
    this.validation.showErrors = true;
    this.validation.valid = this.validation.address;
  }

  selectLocation() {
    this.validate();
    if (this.validation.valid) {
      const coordinates = JSON.parse(JSON.stringify(this.map.getCenter()));
      const { address, address2 } = this;
      this.locationSelected.emit({ ...coordinates, address, address2 });
      this.closeModal();
    }
  }

  public closeModal = () => this.activeModal.close();
}
