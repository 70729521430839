import * as firebase from 'firebase/app';
import 'firebase/database';

// DATABASE METHODS
export function joinRoutes(routes): string {
  return routes.join('/');
}

export function generateID(DB_REF: string): string {
  return firebase.database().ref().child(DB_REF).push().key;
}

export function getDateRoute(date: string): string {
  const year = date.slice(6);
  const month = date.slice(3, 5);
  const day = date.slice(0, 2);
  return year + '/' + month + '/' + day;
}

// DATABASE ROUTES
export const ROOT = 'dev';
// export const DB_ROOT = 'prod';

export const USERS = joinRoutes([ROOT, 'users']);
export const CUSTOMERS = joinRoutes([ROOT, 'customers']);
export const BUSINESS_ACCOUNTS = joinRoutes([ROOT, 'business-accounts']);
export const BUSINESS_PROFILES = joinRoutes([ROOT, 'business-profiles']);
export const BUSINESS_PROGRAMS = joinRoutes([ROOT, 'business-programs']);
export const BUSINESS_MEMBERS = joinRoutes([ROOT, 'business-members']);
export const BUSINESS_ACTIVITY = joinRoutes([ROOT, 'business-activity']);
export const BUSINESS_REDEEMED_REWARDS = joinRoutes([
  ROOT,
  'business-redeemed-rewards',
]);
