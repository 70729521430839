import { Injectable } from '@angular/core';
import * as Chart from 'chart.js';

@Injectable()
export class ChartService {
  constructor() {}

  public generateViewsChart(labels: any[], data: any[], id = 'myBarChart') {
    var ctx = document.getElementById(id);
    var myBarChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: 'Visitas',
            backgroundColor: 'rgba(255, 170, 0, 1)',
            hoverBackgroundColor: 'rgba(255, 170, 0, 0.9)',
            borderColor: '#4e73df',
            data,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 25,
            top: 25,
            bottom: 0,
          },
        },
        scales: {
          // xAxes: [{
          //     time: {
          //         unit: "month"
          //     },
          //     gridLines: {
          //         display: false,
          //         drawBorder: false
          //     },
          //     ticks: {
          //         maxTicksLimit: 6
          //     },
          //     maxBarThickness: 25
          // }],
          yAxes: [
            {
              ticks: {
                min: 0,
                stepSize: 1,
                // max: 15000,
                // maxTicksLimit: 5,
                // padding: 10,
                // Include a dollar sign in the ticks
              },
              gridLines: {
                color: 'rgb(234, 236, 244)',
                zeroLineColor: 'rgb(234, 236, 244)',
                drawBorder: false,
                borderDash: [2],
                zeroLineBorderDash: [2],
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },
    });
  }

  public generateViewsOrdersChart(
    labels: any[],
    views: any[] = [],
    orders: any[] = [],
    internalOrders: any[] = [],
    externalOrders: any[] = [],
    id = 'myBarChart'
  ) {
    var ctx = document.getElementById(id);
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: 'Visitas',
            backgroundColor: '#ffaa00',
            borderColor: '#ffaa00',
            data: views,
          },
          {
            label: 'Pedidos totales',
            backgroundColor: '#0042a6',
            borderColor: '#0042a6',
            data: orders,
          },
          {
            label: 'Pedidos internos',
            backgroundColor: '#ef3f3b',
            borderColor: '#ef3f3b',
            data: internalOrders,
          },
          {
            label: 'Pedidos externos',
            backgroundColor: '#00c956',
            borderColor: '#00c956',
            data: externalOrders,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 25,
            top: 25,
            bottom: 0,
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
          yAxes: [
            {
              ticks: {
                min: 0,
                stepSize: 1,
              },
              gridLines: {
                color: 'rgb(234, 236, 244)',
                zeroLineColor: 'rgb(234, 236, 244)',
                drawBorder: false,
                borderDash: [2],
                zeroLineBorderDash: [2],
              },
            },
          ],
        },
        legend: {
          display: true,
        },
      },
    });
  }
}
