import { Product } from './product.model';

export class MenuCategory {
  name: string;
  products: {
    product: Product;
    visibility: boolean;
  }[];

  constructor() {
    this.name = '';
    this.products = [];
  }
}

export class BusinessMenu {
  id: string;
  categories: MenuCategory[] = [];
  config: {
    orders: {
      available: boolean;
      bill: boolean;
      method: {
        delivery: boolean;
        pickup: boolean;
        table: boolean;
      };
      payment: {
        cash: boolean;
        card: boolean;
        transfer: boolean;
        transferInfo: string;
      };
    };
  };
  createdAt: number;
  updatedAt: number;

  constructor() {
    this.id = '';
    this.categories = [];
    this.config = {
      orders: {
        available: true,
        bill: true,
        method: {
          delivery: true,
          pickup: true,
          table: true,
        },
        payment: {
          cash: true,
          card: true,
          transfer: true,
          transferInfo: '',
        },
      },
    };
    this.createdAt = 0;
    this.updatedAt = 0;
  }
}
