import { BusinessAccount } from './business-account.model';
import { BusinessProfile } from './business-profile.model';
import { BusinessMenu } from './business-menu.model';
import { Plan } from './plan.model';

export class BusinessData {
  id: string;
  profile: BusinessProfile;
  account: BusinessAccount;
  menu: BusinessMenu;
  plans: Plan[];
  customID: string;

  constructor(id: string = '') {
    this.id = id;
    this.profile = new BusinessProfile();
    this.account = new BusinessAccount();
    this.menu = new BusinessMenu();
    this.plans = [];
    this.customID = '';
  }
}
export class PublicBusinessData {
  id: string;
  profile: BusinessProfile;
  menu: BusinessMenu;
  customID: string;

  constructor(id: string = '') {
    this.id = id;
    this.profile = new BusinessProfile();
    this.menu = new BusinessMenu();
    this.customID = '';
  }
}
