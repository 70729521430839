import { NgModule, Optional, SkipSelf } from '@angular/core';

import {
  AdminService,
  AuthService,
  AdminAuthGuardService,
  BusinessAuthGuardService,
  BusinessAccountService,
  BusinessProfileService,
  BusinessMenuService,
  BusinessService,
  ImageService,
  LoadingService,
  ProductService,
  ToastService,
  OrderService,
  CloseModalService,
  AnalyticsService,
  PlanService,
  ConfirmDeactivateGuard,
  ExcelService,
  NotificationService,
  StatsService,
  ChartService,
  BusinessDataService,
  HelperService,
} from '@services/index';

@NgModule({
  declarations: [],
  providers: [
    AnalyticsService,
    AdminService,
    AuthService,
    AdminAuthGuardService,
    BusinessAuthGuardService,
    BusinessAccountService,
    BusinessMenuService,
    BusinessService,
    BusinessProfileService,
    ExcelService,
    ImageService,
    LoadingService,
    OrderService,
    ProductService,
    ToastService,
    PlanService,
    CloseModalService,
    ConfirmDeactivateGuard,
    NotificationService,
    StatsService,
    ChartService,
    BusinessDataService,
    HelperService,
  ],
  imports: [],
  exports: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
